import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigation } from '../NavigationProvider';
import { useAuth } from '../AuthCtx';
import usePatientNavigationPages from '../components/layout/dashboard/navigationPages';

export const useStyles = makeStyles((theme) => {
  return {
    pageGridItem: {
      textDecoration: 'none',
      color: '#666666',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    pageTitle: {
      fontWeight: '600',
      fontSize: '20px',
      fontFamily: 'Roboto',
      lineHeight: '125.19%',
      marginLeft: '2vh',
      marginTop: '50px',
      marginBottom: 'auto',
    },
    page: {
      width: '22vw',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      height: '195px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    welcomeName: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: 'min(43.4717px, 8vw)',
      lineHeight: '51px',
    },
    subTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '1.2rem',
      lineHeight: '154.68%',
      marginBottom: '10px',
      marginTop: '20px',
    },
    topImage: {
      transform: 'scale(90%)',
      height: 'auto',
      [theme.breakpoints.down('lg')]: {
        maxWidth: '100%',
        objectFit: 'cover',
      },
    },
    topImageContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    topLeftContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '50px',
      paddingLeft: '30px',
      paddingBottom: '18px',
    },
  };
});

const TheBox = (props) => {
  return (
    <div
      style={ { padding: '10px',
        boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
        background: 'linear-gradient(150.76deg, #F4F4F4 4.4%, #F9F9F9 82.05%), rgba(255, 255, 255, 0.62)',
        borderRadius: '8px' } }
      className={ props.className }
    >
      {props.children}
    </div>
  );
};

const PagesBox = (props) => {
  const [ hovered, setHovered ] = useState(false);
  const { isDoctor, isCorporate } = useAuth();

  return (
    <div
      className={ props.className }
      style={ { boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
        background: true === props.disabled ? '#a8a8a8' : 'white',
        borderRadius: '16px',
        position: 'relative',
        display: 'flex',
        ...props.styling } }
      onMouseOver={ () => { setHovered(true); } }
      onFocus={ () => { setHovered(true); } }
      onMouseLeave={ () => { setHovered(false); } }
    >
      <div
        className={ props.className }
        style={ {
          padding: '0vw',
          marginLeft: '2vw',
        } }
      >
        {props.children}
      </div>

      <img
        src='/corner.svg'
        alt=''
        style={ {
          position: 'absolute',
          display: true === props.disabled ? 'none' : '',
          bottom: '11px',
          right: '11px',
          width: '25.5px',
          height: '23.5px',
          filter: hovered
            ? isDoctor
              ? 'invert(89%) sepia(7%) saturate(1026%) hue-rotate(176deg) brightness(93%) contrast(87%)'
              : isCorporate ? 'invert(26%) sepia(7%) saturate(2704%) hue-rotate(314deg) brightness(96%) contrast(101%)'
                : 'brightness(0) saturate(100%) invert(78%) sepia(10%) saturate(2971%) hue-rotate(30deg) brightness(90%) contrast(90%)'
            : 'none',
        } }
      />
    </div>
  );
};

export const TopPart = ({ isDoctor = false, isCorporate = false }) => {
  const classes = useStyles();
  const { userAttributes } = useAuth();
  const { t } = useTranslation();

  if (!userAttributes) {
    return null;
  }

  const {
    firstName,
    lastName,
    gender,
    brandName,
  } = userAttributes;

  return (
    <TheBox>
      <Grid container>
        <Grid
          item
          xs={ 12 }
          md={ 12 }
          lg={ 8 }
          className={ classes.topLeftContainer }
          style={ { zIndex: '10' } }
        >
          <div className={ classes.welcomeName }>
            {
              (isDoctor || isCorporate) ? t('formalHello') : t('hello')
            }

            {
              isDoctor ? 'male' === gender ? ` ${ t('mr') } ` : isCorporate ? '' : ` ${ t('mrs') } ` : ''
            }

            {' '}
            {isDoctor ? lastName : isCorporate ? brandName : firstName}
            ,
          </div>
          { !isDoctor && !isCorporate && (
            <>
              <div className={ classes.subTitle }>
                {
                  t('Το προσωπικό σου αρχείο εξατομικευμένων αναλύσεων υγείας και ευζωίας')
                }
              </div>
              <div style={ { width: '80%' } }>
                {t('Εδώ θα βλέπεις τα αποτελέσματα των test που έχεις εγγράψει, θα μπορείς να εγγράψεις νέα kit που θα αγοράσεις, να δίνεις πρόσβαση σε επαγγελματίες υγείας στα αποτελέσματά σου, εφόσον το επιθυμείς, και να διαχειρίζεσαι το προφίλ σου.')}
              </div>
            </>
          )}
        </Grid>
        <Grid
          item
          xs={ 12 }
          md={ 12 }
          lg={ 4 }
          className={ classes.topImageContainer }
          style={ { zIndex: '8' } }
        >
          <img src='/hand-holding-puzzle-piece.png' alt='' className={ classes.topImage } />
        </Grid>
      </Grid>
    </TheBox>
  );
};

export const Page = ({ pageInfo, styling = null }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <PagesBox
      className={ classes.page }
      disabled={ pageInfo.isDisabled }
      styling={ styling }
    >
      <pageInfo.BigIcon isDisabled={ pageInfo.isDisabled } />
      <div className={ classes.pageTitle }>
        {t(pageInfo.title)}
      </div>
    </PagesBox>
  );
};

const Pages = () => {
  const navigationPages = usePatientNavigationPages();
  const { navigateToPage } = useNavigation();
  const classes = useStyles();

  return (
    <Grid container spacing={ 2 }>
      {
        navigationPages.filter((page) => {
          if ('general' === page.id) {
            return false;
          }

          const {
            isMenuHidden,
          } = { ...{ isMenuHidden: false }, ...page };
          return !isMenuHidden;
        })
          .map((page) => {
            const href = `/?pid=${ page.id }`;
            return (
              <Grid
                className={ classes.pageGridItem }
                item
                xs={ 12 }
                md={ 6 }
                lg={ 4 }
                component={ true === page.isDisabled ? 'div' : 'a' }
                href={ href }
                key={ page.id }
                onClick={
                  (e) => {
                    e.preventDefault();
                    if (true !== page.isDisabled) {
                      navigateToPage({ pageId: page.id });
                    }
                  }
                }
              >
                <Page pageInfo={ page } />
              </Grid>
            );
          })
      }
    </Grid>
  );
};

const WelcomePage = () => {
  return (
    <>
      <Grid container spacing={ 5 }>
        <Grid item xs={ 12 }>
          <TopPart />
        </Grid>
        <Grid item xs={ 12 }>
          <Pages />
        </Grid>
      </Grid>
    </>
  );
};

export default WelcomePage;
