import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { Auth } from 'aws-amplify';
import errors from './errors';
import generateQRCode from './components/app/mfa-components/generateQRCode';
import cognitoPools from './aws-exports';

export const SyncStatus = {
  Idle: 'Idle',
  Loading: 'Loading',
  Success: 'Success',
  Error: 'Error',
};

export const UserStatus = {
  Guest: 'Guest',
  Unconfirmed: 'Unconfirmed',
  SemiAuthenticated: 'SemiAuthenticated',
  Authenticated: 'Authenticated',
};

export const UserGroups = {
  Patient: 'Patient',
  Doctor: 'Doctor',
  Corporate: 'Corporate',
};

const defaultUserGroup = UserGroups.Patient;

const configs = [
  {
    userGroup: UserGroups.Patient,
    config: cognitoPools.usersConfig,
  },
  {
    userGroup: UserGroups.Doctor,
    config: cognitoPools.doctorsConfig,
  },
  {
    userGroup: UserGroups.Corporate,
    config: cognitoPools.corporateConfig,
  },
];

Auth.configure(configs.find((c) => { return c.userGroup === defaultUserGroup; }).config);

export const AuthContext = React.createContext({});

function Mutex() {
  let current = Promise.resolve();
  this.lock = () => {
    let _resolve;
    const p = new Promise((resolve) => {
      _resolve = () => { return resolve(); };
    });
    // Caller gets a promise that resolves when the current outstanding
    // lock resolves
    const rv = current.then(() => { return _resolve; });
    // Don't allow the next request until the new promise is done
    current = p;
    // Return the new promise
    return rv;
  };
}

export const AuthProvider = ({ children }) => {
  const [ userGroup, setUserGroupAux ] = React.useState(UserGroups.Patient);
  const [ aux, setAux ] = React.useState(null);
  const [ user, setUser ] = React.useState(null);
  const [ userAttributes, setUserAttributes ] = React.useState(null);
  const [ userAttributesLoaded, setUserAttributesLoaded ] = React.useState(false);
  const [ status, setStatus ] = React.useState(null);
  const [ deliveryDetails, setDeliveryDetails ] = React.useState(null);
  const [ syncStatus, setSyncStatus ] = React.useState(SyncStatus.Idle);
  const [ verCodeAlreadySend, setVerCodeAlreadySend ] = useState(false);
  const queryClient = useQueryClient();

  const isDoctor = UserGroups.Doctor === userGroup;
  const isCorporate = UserGroups.Corporate === userGroup;

  const setUserGroup = (newUserGroup) => {
    const conf = configs.find((c) => { return c.userGroup === newUserGroup; });
    if (!conf) {
      throw new Error('Unknown user group');
    }
    Auth.configure(conf.config);
    setUserGroupAux(newUserGroup);
    setUser(null);
    setStatus(UserStatus.Guest);
  };

  const syncAuth = () => {
    const mutex = new Mutex();

    const tries = configs.map(async (conf) => {
      const unlock = await mutex.lock();
      try {
        Auth.configure(conf.config);
        const authUser = await Auth.currentAuthenticatedUser();
        return [ conf, authUser ];
      } finally {
        unlock();
      }
    });

    return Promise.any(tries)
      .then(([ conf, currentAuthenticatedUser ]) => {
        setUserGroup(conf.userGroup);
        setUser(currentAuthenticatedUser);
        setStatus(UserStatus.Authenticated);
      })
      .catch((err) => {
        setUserGroup(defaultUserGroup);
        setUser(null);
        setStatus(UserStatus.Guest);
        console.debug(err);
      });
  };

  const refreshUserAttributes = () => {
    console.debug('****');
    return Auth.currentAuthenticatedUser()
      .then((info) => {
        console.debug('info', info);
        if (!info || !info.attributes) {
          return;
        }
        const {
          email,
          address,
          birthdate: dateOfBirth,
          // email,
          // eslint-disable-next-line camelcase
          family_name: lastName,
          gender,
          // given_name,
          locale,
          // eslint-disable-next-line camelcase
          middle_name: middleName,
          name: firstName,
          // nickname,
          // eslint-disable-next-line camelcase
          phone_number: mobileNumber,
          // picture,
          // preferred_username,
          // profile,
          // zoneinfo,
          // updated_at,
          // website,
          'custom:country': country,
          'custom:house_number': houseNumber,
          'custom:business_number': businessNumber,
          'custom:specialty': specialty, // only if it is a doctor
          'custom:verification_status': verificationStatus, // only if it is a doctor
          'custom:brand_name': brandName, // only if it is a doctor
        } = info.attributes;
        let jsonAddress = {};
        try {
          jsonAddress = JSON.parse(address || JSON.stringify({}));
        } catch (e) {
          console.debug(e);
          // deprecated address;
          jsonAddress = { streetAddress: address };

          if (null != address.postalCode && null != address.streetAddress && null != address.city) {
            jsonAddress = { streetAddress: address.streetAddress,
              postalCode: address.postalCode,
              city: address.city,
              streetNumber: address.street_number };
          }
        }
        const { streetAddress, postalCode, city, street_number: streetNumber } = jsonAddress;
        console.debug('.\n\n\n.');
        setUserAttributes({
          email,
          firstName,
          lastName,
          streetAddress,
          postalCode,
          city,
          gender,
          locale,
          dateOfBirth,
          houseNumber,
          country,
          businessNumber,
          mobileNumber,
          middleName,
          specialty,
          verificationStatus,
          brandName,
          streetNumber,
        });
        setUserAttributesLoaded(true);
      });
  };

  React.useEffect(() => {
    refreshUserAttributes()
      .then(() => {
        console.debug('User attributes have been refreshed!');
      });
  }, [ user, userGroup ]);

  React.useEffect(() => {
    setSyncStatus(SyncStatus.Loading);
    syncAuth()
      .then(() => {
        setSyncStatus(SyncStatus.Success);
      })
      .catch(() => {
        setSyncStatus(SyncStatus.Error);
      })
      .finally(() => {
        console.debug('Sync auth completed!');
      });
  }, []);

  const updateUserAttributes = (
    {
      firstName,
      lastName,
      gender,
      dateOfBirth,
      streetAddress,
      streetNumber,
      postalCode,
      city,
      mobileNumber,
      houseNumber,
      businessNumber,
      specialty,
      locale,
      middleName,
      country,
      brandName,
    },
  ) => {
    const address = JSON.stringify({ streetAddress, postalCode, city, street_number: streetNumber });

    const attributes = {
      address,
      birthdate: dateOfBirth,
      // email,
      // eslint-disable-next-line camelcase
      family_name: lastName,
      gender,
      // given_name,
      locale,
      // eslint-disable-next-line camelcase
      middle_name: middleName,
      name: firstName,
      // nickname,
      // eslint-disable-next-line camelcase
      phone_number: mobileNumber,
      // picture,
      // preferred_username,
      // profile,
      // zoneinfo,
      // updated_at,
      // website,
      'custom:country': country,
      'custom:house_number': houseNumber,
      'custom:business_number': businessNumber,
      'custom:specialty': specialty, // doctor
      'custom:brand_name': brandName, // doctor
    };
    Object.keys(attributes).forEach((key) => { return attributes[key] === undefined && delete attributes[key]; });
    return Auth.currentAuthenticatedUser()
      .then((cognitoUser) => {
        return Auth.updateUserAttributes(
          cognitoUser,
          attributes,
        );
      })
      .then(() => {
        return refreshUserAttributes();
      });
  };

  const signUp = ({ username, password, firstName, lastName, birthdate, specialty, gender,
    country, brandName, emailLanguage }) => {
    setVerCodeAlreadySend(true);
    return Auth.signUp({
      username,
      password,
      attributes: {
        ...!isCorporate && { name: firstName },
        ...!isCorporate && { family_name: lastName },
        ...!isCorporate && { gender },
        ...!isCorporate && { birthdate },
        'custom:country': country,
        'custom:email_locale': emailLanguage,
        ...isDoctor && { 'custom:specialty': specialty },
        ...(isDoctor || isCorporate) && { 'custom:brand_name': brandName },
      },
    }).then((registrationDetails) => {
      console.debug(registrationDetails);
      setAux((prev) => { return { ...prev, username, password }; });
      const { codeDeliveryDetails, userConfirmed } = registrationDetails;
      if (false === userConfirmed && !codeDeliveryDetails) {
        throw errors.SomethingWentWrong;
      }
      if (false === userConfirmed) {
        setStatus(UserStatus.Unconfirmed);
      }
      if (codeDeliveryDetails) {
        setDeliveryDetails(
          {
            CodeDeliveryDetails: codeDeliveryDetails, // Amazon is fucked up
          },
        );
      }
    })
      .catch((err) => {
        // UsernameExistsException
        const { code } = err;
        if (!code) {
          throw errors.SomethingWentWrong;
        }
        switch (code) {
          case 'UsernameExistsException':
            throw errors.auth.AccountAlreadyExists;
          default:
            break;
        }
      });
  };

  const confirmSignUp = ({ code: confirmationCode }) => {
    if (!aux || !aux.username) {
      return Promise.reject(errors.SomethingWentWrong);
    }
    return Auth.confirmSignUp(aux.username, confirmationCode)
      .catch((err) => {
        console.debug(err);
        const { code } = err;
        switch (code) {
          case 'CodeMismatchException':
            throw errors.auth.InvalidConfirmationCode;
          default:
            throw errors.SomethingWentWrong;
        }
      });
  };

  const signIn = ({ username, password }) => {
    return Auth.signIn(username, password)
      .then((cognitoUser) => {
        setAux(null);
        setUser(cognitoUser);
        const { challengeName } = cognitoUser;
        if (!challengeName) {
          setStatus(UserStatus.Authenticated);
          return;
        }
        switch (challengeName) {
          case 'SOFTWARE_TOKEN_MFA':
            setStatus(UserStatus.SemiAuthenticated);
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        setAux((prev) => { return { ...prev, username, password }; });
        const { code } = err;
        if (!code) {
          throw errors.SomethingWentWrong;
        }

        if ('User is disabled.' === err.message) {
          throw errors.auth.Disabled;
        }

        // eslint-disable-next-line no-unreachable
        switch (code) {
          case 'NotAuthorizedException':
            // Incorrect username or password.
            throw errors.auth.InvalidCredentials;
          case 'UserNotConfirmedException':
            setStatus(UserStatus.Unconfirmed);
            break;
          default:
            setStatus(null);
        }
      });
  };

  const confirmSignIn = ({ code }) => {
    return Auth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA')
      .then(() => {
        setStatus(UserStatus.Authenticated);
        return Auth.currentAuthenticatedUser()
          .then(setUser);
      });
  };

  const automaticSignIn = () => {
    if (!aux || !aux.username || !aux.password) {
      return Promise.reject(errors.SomethingWentWrong);
    }
    return signIn({ username: aux.username, password: aux.password });
  };

  const doesUserExist = async (type, email) => {
    const value = await axios.post(`${ process.env.REACT_APP_API_URL }${ type }/admin-get-user`,
      {
        email,
      },
      {
        headers: {
          'x-api-key': 'customers' === type
            ? 'mobile_customer_secret_key_8bjg2a91-4519-6924-a4hi-5cc732edg79k'
            : 'businesses' === type ? 'woo_secret_key_8e1fdb02-6173-4062-b3ba-6ba170caac2d'
              : 'mobile_doctor_secret_key_8dit6b10-3226-4978-s3hj-7nn959tsl15p',
        },
      }).then((dd) => {
      return dd.data;
    });

    return 'UserNotFoundException' !== value?.code;
  };

  const resendSignUp = () => {
    if (!aux || !aux.username) {
      return Promise.reject(errors.SomethingWentWrong);
    }
    return Auth.resendSignUp(aux.username)
      .then((deliveryDetailsResp) => {
        setDeliveryDetails(deliveryDetailsResp);
      });
  };

  const signOut = () => {
    return Auth.signOut()
      .then(() => {
        queryClient.removeQueries();
        setUser(null);
        setStatus(UserStatus.Guest);
        setUserAttributes(null);
      })
      .catch((err) => {
        console.debug('eerrr', err);
      });
  };

  const forgotPassword = ({ username }) => {
    setAux((prev) => { return { ...prev, username }; });
    return Auth.forgotPassword(username)
      .then(setDeliveryDetails);
  };

  const forgotPasswordSubmit = ({ code, newPassword }) => {
    return Auth.forgotPasswordSubmit(aux.username, code, newPassword)
      .then(() => {
        setAux((prev) => { console.log('new password', newPassword); return { ...prev, password: newPassword }; });
      });
  };

  const ctx = {
    user,
    syncStatus,
    userGroup,
    setUserGroup,
    userAttributes,
    userAttributesLoaded,
    status,
    deliveryDetails,
    signIn,
    confirmSignIn,
    automaticSignIn,
    signUp,
    confirmSignUp,
    resendSignUp,
    signOut,
    forgotPassword,
    forgotPasswordSubmit,
    updateUserAttributes,
    refreshUserAttributes,
    isDoctor,
    isCorporate,
    doesUserExist,
    verCodeAlreadySend,
    setVerCodeAlreadySend,
  };

  return (
    <AuthContext.Provider value={ ctx }>
      {children}
    </AuthContext.Provider>
  );
};

export const useMFA = () => {
  const [ mfaEnabled, setMFAEnabled ] = useState(null);
  const [ totpUri, setTOTPUri ] = useState(null);
  const [ qrCode, setQRCode ] = useState(null);

  const {
    user,
    setUser,
  } = React.useContext(AuthContext);

  useEffect(() => {
    const mfaDisabled = !user || !user.preferredMFA || 'NOMFA' === user.preferredMFA;
    setMFAEnabled(!mfaDisabled);
  }, [ user, setMFAEnabled ]);

  const setupMFA = () => {
    return Auth.currentAuthenticatedUser()
      .then((cognitoUser) => {
        return Auth.setupTOTP(cognitoUser);
      }).then((secretCode) => {
        return generateQRCode(secretCode);
      }).then(({ uri, uriBase64 }) => {
        setTOTPUri(uri);
        setQRCode(uriBase64);
      }).catch((err) => {
        setTOTPUri(null);
        setQRCode(null);
        throw err;
      });
  };

  const enableMFA = ({ code }) => {
    return Auth.currentAuthenticatedUser()
      .then((cognitoUser) => {
        return Auth.verifyTotpToken(cognitoUser, code)
          .then(() => {
            return Auth.setPreferredMFA(cognitoUser, 'TOTP');
          });
      })
      .then(() => {
        return Auth.currentAuthenticatedUser()
          .then(setUser);
      });
  };

  const disableMFA = () => {
    return Auth.currentAuthenticatedUser()
      .then((cognitoUser) => {
        return Auth.setPreferredMFA(cognitoUser, 'NOMFA');
      })
      .then(() => {
        return Auth.currentAuthenticatedUser()
          .then(setUser);
      });
  };

  return {
    totpUri,
    qrCode,
    mfaEnabled,
    setupMFA,
    enableMFA,
    disableMFA,
  };
};

export const useAuth = (client = undefined) => {
  const {
    user,
    syncStatus,
    userAttributes,
    userAttributesLoaded,
    status,
    userGroup,
    setUserGroup,
    deliveryDetails,
    signIn,
    confirmSignIn,
    automaticSignIn,
    signUp,
    confirmSignUp,
    resendSignUp,
    signOut,
    forgotPassword,
    forgotPasswordSubmit,
    updateUserAttributes,
    isDoctor,
    refreshUserAttributes,
    doesUserExist,
    verCodeAlreadySend,
    setVerCodeAlreadySend,
    isCorporate,
  } = React.useContext(AuthContext);

  const userIsGuest = status === UserStatus.Guest;
  const userIsUnconfirmed = status === UserStatus.Unconfirmed;
  const userIsSemiAuthenticated = status === UserStatus.SemiAuthenticated;
  const userIsAuthenticated = status === UserStatus.Authenticated;

  const userHasRequiredAttributes = user && user.attributes
    && user.attributes.birthdate
    && user.attributes.address
    && user.attributes.gender
    && user.attributes.phone_number;

  const doctorHasRequiredAttributes = userAttributes
    && userAttributes.firstName
    && userAttributes.lastName
    && userAttributes.specialty
    && userAttributes.streetAddress
    && userAttributes.postalCode
    && userAttributes.city
    && userAttributes.businessNumber;

  const { data: databaseUser, isLoading: databaseUserLoading } = useQuery([ 'me', 'backend' ], () => {
    return client.get('businesses/me');
  }, {
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: client !== undefined && userIsAuthenticated && isCorporate,
  });

  return {
    user,
    status,
    userGroup,
    setUserGroup,
    signIn,
    confirmSignIn,
    databaseUser,
    databaseUserLoading,
    automaticSignIn,
    signUp,
    confirmSignUp,
    resendSignUp,
    signOut,
    forgotPassword,
    forgotPasswordSubmit,
    updateUserAttributes,
    userAttributes,
    userAttributesLoaded,
    deliveryDetails,
    syncStatus,
    userIsGuest,
    userIsUnconfirmed,
    userIsSemiAuthenticated,
    userIsAuthenticated,
    userHasRequiredAttributes: !!userHasRequiredAttributes,
    doctorHasRequiredAttributes: !!doctorHasRequiredAttributes,
    refreshUserAttributes,
    isDoctor,
    isCorporate,
    doesUserExist,
    verCodeAlreadySend,
    setVerCodeAlreadySend,
  };
};
