/* eslint-disable no-unused-vars */
import AppBar from '@material-ui/core/AppBar';
import { AppCtx } from 'AppCtx';
import { useFormStylesUp } from 'components/forms/form-styles-signup';
import CheckboxDialog from 'components/app/to-be-refactored/CheckboxDialog';
import { useInputValidation } from 'hooks/form-rules';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles as useSignUpStyles } from 'components/forms/SignUpForm';
import { useAuth } from 'AuthCtx';
import { useHistory } from 'react-router';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
// import Badge from '@material-ui/core/Badge';
import { Typography, Icon, ClickAwayListener, Popper, Modal, Dialog, DialogContent, DialogActions, Button, DialogContentText } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { FilePDFViewer } from 'components/PDFViewer';
import { LoadingButtonNoGrid } from 'components/forms/pgx-forms/EntitlePersonalResultsForm';
import useAPI from '../../hooks/api-hooks';
import { useNavigation } from '../../NavigationProvider';
import { Logo } from './app-header';
import useMediaQueries from '../../hooks/media-query-hooks';
import MobileMenu from './atoms/MobileMenu';
// import MenuButton from '../app/MenuButton';
import LangSwitcher, { langTypeToText } from '../app/LangSwitcher';
import { NewKitRegistrationModal } from './AppHeader';

const useStyles = makeStyles((theme) => {
  return {
    appBar: {
      height: theme.layout.topBarHeight,
      overflow: 'hidden', // TODO: (this is a temporary solution) Remove overflow and fix the horizontal scrollbar problem
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
      backgroundColor: theme.palette.alternate.main,
      flexDirection: 'row',
      justifyContent: 'center',
      zIndex: theme.zIndex.drawer + 1,
      padding: theme.spacing(0, 2),
    },
    inner: {
      maxWidth: theme.layout.contentWidth,
    },

    menuEshop: {
      color: '#5f5f5f',
      background: 'transparent',
      '&:hover': {
        color: 'white',
        background: '#526A9D',
      },
    },

    menuCalendar: {
      color: '#5f5f5f',
      background: 'transparent',
      '&:hover': {
        color: 'white',
        background: '#93C221',
      },
    },

    menuProfile: {
      color: '#5f5f5f',
      background: 'transparent',
      '&:hover': {
        color: 'white',
        background: '#9C9BEE',
      },
    },

    menuNotifications: {
      color: '#5f5f5f',
      background: 'transparent',
      '&:hover': {
        color: 'white',
        background: '#D89BEE',
      },
    },
    menuItem: {
      height: '51px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',

      '&:hover': {
        cursor: 'pointer',
      },
    },
    popupMenu: {
      marginTop: '3px',
      width: '200px',
      background: 'white',
      boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
      borderRadius: '16px',
      paddingLeft: '10px',
      border: '1px solid rgba(145, 145, 145, 0.3)',
    },
    userNLang: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '12rem',
    },
    menu: {
      boxShadow: '0px 33.6px 112px rgba(0, 0, 0, 0.05), 0px 7.505px 25.0167px rgba(0, 0, 0, 0.0298054), 0px 2.23443px 7.44811px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '22.4px',
      display: 'flex',
      background: 'white',
      padding: '10px',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '98px',
      height: '42px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  };
});

const UseAppModal = ({ setNoVerificationModal }) => {
  const { t } = useTranslation();

  return (
    <div
      style={ {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'white',
        boxShadow: '24px',
        textTransform: 'none',
        borderRadius: '15px',
        padding: '50px',
      } }
    >
      <IconButton
        style={ { color: 'red', position: 'absolute', right: '10px', top: '10px', margin: 0, padding: 0 } }
        onClick={
          () => {
            localStorage.setItem('showedNoVerification', 'true');
            setNoVerificationModal(false);
          }
        }
      >
        <CloseIcon />
      </IconButton>
      <div style={ { display: 'flex', alignItems: 'center', gap: '20px' } }>
        <Typography variant='h4'>
          {t('Αναμονή Εγκρισης Εγγραφής')}
        </Typography>
      </div>
    </div>
  );
};

export const Menu = ({ history }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={ classes.popupMenu }>
      <Grid item xs={ 12 } style={ { width: '100%' } }>
        <div className={ classes.menuItem }>
          <Icon>
            <img src='/signout.svg' alt='logout' />
          </Icon>
          <div
            style={ { paddingTop: '5px', marginLeft: '12px' } }
            aria-hidden
            onClick={ () => {
              localStorage.setItem('showedNoVerification', 'false');
              history.push('/logout');
            } }
          >
            {t('logout')}
          </div>
          <KeyboardArrowRightIcon style={ { marginLeft: 'auto', marginRight: '10px' } } />
        </div>
      </Grid>
    </Grid>
  );
};

const AppHeader = () => {
  const { navigateToPage } = useNavigation();
  const classes = useStyles();

  const { isMd, isMdDown } = useMediaQueries();
  const { userIsAuthenticated, userAttributes } = useAuth();

  const [ anchorMenu, setAnchorMenu ] = React.useState(null);

  const alreadySawIt = localStorage.getItem('showedNoVerification') || 'false';

  const { setShowNewRegiKitModal, showNewRegiKitModal, justBoughtAKit, setJustBoughtAKit } = React.useContext(AppCtx);

  const handleClick = (event) => {
    setAnchorMenu(anchorMenu ? null : event.currentTarget);
  };

  const history = useHistory();
  const menuOpen = Boolean(anchorMenu);
  const menuId = menuOpen ? 'menu-popper' : undefined;

  const { client } = useAPI();

  const [ showNewGDPRModal, setShowNewGDPRModal ] = useState(false);

  const updateEmailLanguage = (lang) => {
    client.put('businesses/me/details', {
      email_locale: langTypeToText[lang].value,
    });
  };

  if (!isMd && userIsAuthenticated) {
    return (
      <AppBar style={ { background: 'white' } }>
        {
          justBoughtAKit && (
            <Modal
              open={ justBoughtAKit }
              onClose={ () => { setShowNewRegiKitModal(false); setJustBoughtAKit(false); } }
            >
              <NewKitRegistrationModal
                setShowNewRegiKitModal={ setShowNewRegiKitModal }
                justBoughtAKit={ justBoughtAKit }
                setJustBoughtAKit={ setJustBoughtAKit }
              />
            </Modal>
          )
        }

        <MobileMenu isDoctor />
      </AppBar>
    );
  }

  return (
    <AppBar position='relative' className={ classes.appBar }>

      {
        userIsAuthenticated && (
          <Modal
            open={ justBoughtAKit }
            onClose={ () => { setShowNewRegiKitModal(false); setJustBoughtAKit(false); } }
          >
            <NewKitRegistrationModal
              setShowNewRegiKitModal={ setShowNewRegiKitModal }
              justBoughtAKit={ justBoughtAKit }
              setJustBoughtAKit={ setJustBoughtAKit }
            />
          </Modal>
        )
      }

      <Grid
        container
        className={ classes.inner }
        alignItems='stretch'
        justifyContent='space-between'
      >
        <Grid item xs={ 3 } container alignItems='center'>
          <Logo />
        </Grid>
        <Grid item xs>
          <Grid
            container
            justifyContent='space-between'
            alignItems='stretch'
            style={ { height: '100%' } }
          >
            <Grid item>
              <Typography />
              <Typography className={ classes.menuCalendar } />
              <Typography className={ classes.menuProfile } />
              <Typography className={ classes.menuNotifications } />
            </Grid>
            <div className={ classes.userNLang }>
              <Grid item style={ { alignSelf: 'center' } }>
                <LangSwitcher onChange={ updateEmailLanguage } />
              </Grid>
              {
                userIsAuthenticated && userIsAuthenticated && (
                  <Grid
                    item
                    className={ classes.menu }
                    style={ { alignSelf: 'center' } }
                  >
                    <ClickAwayListener onClickAway={ () => { setAnchorMenu(null); } }>
                      <div>
                        <div
                          aria-hidden
                          onClick={ handleClick }
                        >
                          <img
                            src='/threeLines.png'
                            alt='threeLines'
                            style={ { marginTop: '3px' } }
                          />
                        </div>
                        <Popper
                          id={ menuId }
                          open={ menuOpen }
                          anchorEl={ anchorMenu }
                          style={ { zIndex: 10000 } }
                        >
                          <Menu history={ history } setAnchorMenu={ setAnchorMenu } />
                        </Popper>
                      </div>
                    </ClickAwayListener>
                    <div
                      aria-hidden
                      onClick={ () => { navigateToPage({ pageId: 'profile' }); } }
                    >
                      <img src='/userIcon.png' alt='' />
                    </div>
                  </Grid>
                )
              }
            </div>
          </Grid>

        </Grid>

        <Grid
          item
          style={ { display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingLeft: '15px',
            cursor: 'pointer' } }
          xs='auto'
          onClick={ () => {
            history.push('/');
          } }
        >
          <img
            alt='emr'
            src='/idna-laboratories.png'
            style={ { maxHeight: isMdDown ? '45px' : '50px' } }
            height='100%'
            width='auto'
          />
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default AppHeader;
/*
  MenuButton className={ classes.menuEshop } />
              <MenuButton className={ classes.menuCalendar } />
              <MenuButton className={ classes.menuProfile } />
              <MenuButton className={ classes.menuNotifications }>
                <Badge badgeContent={ 0 } color='error' />
              </MenuButton>
*/
