import { Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQueries from 'hooks/media-query-hooks';
import CardBase from '../app/CardBase';
import SectionAlternate from '../app/DoctorSectionAlternate';
import { NavigationProvider } from '../../NavigationProvider';
import dnaBg from '../../assets/dna_bg.png';
import Menu from './atoms/DoctorMenu';
import Pages from './atoms/Pages';
import useCorporateNavigationPages from './corporate-dashboard/navigationPages';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: '100%',
      width: '100%',
    },
    section: {
      '& .section-alternate__content': {
        paddingTop: 0,
        marginTop: theme.spacing(-5),
        position: 'relative',
        zIndex: 1,
        marginLeft: '0',
      },
      '& .card-base__content': {
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(2),
        },
      },
      backgroundColor: 'transparent',
    },
    menu: {
      height: 'auto',
    },
  };
});

const CorporateDashboard = () => {
  const classes = useStyles();

  const theme = useTheme();

  const navigationPages = useCorporateNavigationPages();
  const { isSm } = useMediaQueries();

  return (
    <NavigationProvider
      pageKey='pid'
      fallbackPageId='general'
      cardioSerial={ undefined }
      _threeToOneSerial={ undefined }
      _nutritionSerial={ undefined }
      _vitaminsSerial={ undefined }
      _sportsSerial={ undefined }
      _bioAgeSerial={ undefined }
      _pgxSerial={ undefined }
      _nutrigenetixSerial={ undefined }
    >
      <div className={ classes.root }>
        <div style={
          {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.3,
          }
        }
        >
          <img alt='dna' src={ dnaBg } width='auto' height='100%' />
        </div>
        <div style={ { position: 'relative', width: '100%', minHeight: '100px', backgroundColor: theme.palette.primary.main } } />
        <SectionAlternate className={ classes.section }>
          <Grid container spacing={ 4 } alignItems='flex-start'>
            <Grid
              item
              xs={ 12 }
              md={ 4 }
              lg={ 3 }
              style={ { display: isSm ? 'none' : '' } }
            >
              <CardBase withShadow align='left' className={ classes.menu }>
                <Menu pages={ navigationPages } />
              </CardBase>
            </Grid>
            <Grid item xs={ 12 } md={ 8 } lg={ 9 }>
              <Pages pages={ navigationPages } />
            </Grid>
          </Grid>
        </SectionAlternate>
      </div>
    </NavigationProvider>
  );
};

export default CorporateDashboard;
