/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import { Dialog, Grid, DialogTitle, Typography, DialogContent, FormControl, Radio,
  TextField, FormControlLabel, InputLabel, MenuItem, DialogActions, Select, Button,
  FormLabel,
  RadioGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useAPI from 'hooks/api-hooks';
import { useAlerts } from 'hooks/alerts-hooks';
import useMediaQueries from 'hooks/media-query-hooks';
import CountySelectField from './atoms/CountySelectField';
import { countries } from './atoms/CountrySelectField';

const Payment = ({ sureDialogOpen, setSureDialogOpen, paymentDetails, setPaymentDetails,
  // eslint-disable-next-line no-unused-vars
  handlePGxKitSubmit, isKitPGX, isKitOnlyNutri, isKitCvd, paymentType, setPaymentType, kitName, cost,
  thematicPackage, receiptType, setReceiptType }) => {
  const { t } = useTranslation();

  const { isSm } = useMediaQueries();

  const [ checkErrors, setCheckErrors ] = useState(false);

  const [ promoCode, setPromoCode ] = useState('');
  const [ paymentMethod, setPaymentMethod ] = useState('credit-card');

  const { alertSuccess } = useAlerts();

  const { client } = useAPI();

  const [ promoCodeApplied, setPromoCodeApplied ] = useState(false);

  const [ costAfterPromo, setCostAfterPromo ] = useState(cost);
  const [ promoCodeEntity, setPromoCodeEntity ] = useState(undefined);

  const areDetailsMissing = useMemo(() => {
    return null == paymentDetails || '' === paymentDetails.first_name || null == paymentDetails.first_name
    || '' === paymentDetails.last_name || null == paymentDetails.last_name
    || '' === paymentDetails.billing_address || null == paymentDetails.billing_address
    || '' === paymentDetails.billing_zip_code || null == paymentDetails.billing_zip_code
    || '' === paymentDetails.billing_city || null == paymentDetails.billing_city
    || '' === paymentDetails.billing_country || null == paymentDetails.billing_country
    || '' === paymentDetails.customer_email || null == paymentDetails.customer_email
    || '' === paymentDetails.customer_phone || null == paymentDetails.customer_phone;
  }, [ paymentDetails ]);

  const validateField = (value) => {
    return null != value && '' !== value;
  };

  const handleFormSubmit = () => {
    if (![
      () => { return validateField(paymentDetails.first_name); },
      () => { return validateField(paymentDetails.last_name); },
      () => { return validateField(paymentDetails.billing_address); },
      () => { return validateField(paymentDetails.billing_city); },
      () => { return validateField(paymentDetails.billing_county); },
      () => { return validateField(paymentDetails.billing_zip_code); },
      () => { return validateField(paymentDetails.billing_country); },

      ...'invoice' === receiptType ? (
        [
          () => { return validateField(paymentDetails.billingBrandName); },
          () => { return validateField(paymentDetails.billingCompanyActivity); },
          () => { return validateField(paymentDetails.billingAfm); },
        ]
      ) : [],

      () => { return validateField(paymentDetails.customer_email); },
      () => { return validateField(paymentDetails.customer_phone); },

      () => { return validateField(paymentDetails.installments); },

    ].every((f) => {
      return true === f();
    })) {
      setCheckErrors(true);
      return false;
    }

    return true;
  };

  const logo = 'NutriGenetix Δυναμική Παρακολούθηση' === kitName ? 'repeat.png'
    : isKitCvd ? '/CVD_logo.png' : isKitOnlyNutri ? 'Nutrigenetix_logo.png'
      : '/pgx_logo.png';

  const applyPromoCode = () => {
    return client.post('/promotion-codes/validation', {
      code: promoCode,
      thematic_package: thematicPackage,
    }).then((dd) => {
      alertSuccess(t('Promo code was applied successfully'));
      setCostAfterPromo(dd.amount);
      setPromoCodeEntity(dd);
      setPromoCodeApplied(true);
      return dd;
    }).catch((e) => {
      console.error('e', e);
    });
  };

  return (
    <Dialog
      open={ sureDialogOpen }
      onClose={ () => { setSureDialogOpen(false); } }
      aria-labelledby='alert-dialog-title-review'
      aria-describedby='alert-dialog-description-review'
      maxWidth='md'
      fullWidth
      PaperProps={ {
        style: {
          maxHeight: '100%',
        },
      } }
    >
      <Grid container>
        <Grid
          item
          xs={ 12 }
        >
          <DialogContent
            style={ { display: 'flex',
              justifyContent: 'center',
              paddingTop: '10px',
              alignItems: 'center',
              flexWrap: 'wrap',
              flexDirection: 'column' } }
          >
            <div
              style={ { display: 'flex',
                flexDirection: 'row',
                gap: '28px',
                justifyContent: 'center',
                paddingTop: '10px',
                alignItems: 'center' } }
            >
              <div>
                <img
                  src={ logo }
                  alt={ logo }
                  style={ {
                    height: '80px',
                    boxShadow: 'NutriGenetix Δυναμική Παρακολούθηση' !== kitName ? 'rgba(0, 0, 0, 0.24) 0px 3px 8px' : '',
                  } }
                />
              </div>

              <div>
                <Typography
                  variant='body2'
                  style={ { fontWeight: 500,
                    fontSize: '1.4rem' } }
                >
                  {('iDNA NutriGenetix kit' === kitName || 'NutriGenetix Laboratories kit' === kitName)
                    ? 'iDNA NutriGenetix Kit' : t(kitName)}
                </Typography>

                <div
                  style={ { display: 'flex',
                    justifyContent: 'flex-start',
                    marginTop: '10px',
                    alignItems: 'center',
                    gap: '5px' } }
                >
                  <Typography
                    variant='body1'
                    style={ {
                      fontWeight: 'bold',
                      fontSize: '1.3rem',
                    } }
                  >
                    {t('price')}
                    :
                  </Typography>

                  <Typography
                    style={ {
                      fontWeight: 'bold',
                      fontSize: '1.3rem',
                    } }
                  >
                    {
                      costAfterPromo !== cost ? (
                        <>
                          <span
                            style={ {
                              textDecoration: 'line-through',
                              fontWeight: 'bold',
                              fontSize: '1.3rem',
                              color: 'rgba(0, 0, 0, 0.26)',
                            } }
                          >
                            {cost}
                          </span>
                          {' '}
                          {costAfterPromo}
                        </>
                      ) : costAfterPromo
                    }
                    {' '}
                    €
                  </Typography>
                </div>
              </div>
            </div>
          </DialogContent>
        </Grid>

        <Grid
          item
          xs={ 12 }
          style={ {
            display: 'flex', justifyContent: 'center',
          } }
        >
          <TextField
            variant={ costAfterPromo !== cost ? 'standard' : 'outlined' }
            size='small'
            color='secondary'
            disabled={ costAfterPromo !== cost }
            value={ promoCode }
            style={ {
              marginRight: '0',
              color: costAfterPromo !== cost ? 'black' : '',
            } }
            label={ costAfterPromo !== cost ? '' : t('reg_kit.review.common.coupon_code') }
            onChange={ (e) => {
              setPromoCode(e.target.value);
            } }
            InputProps={ {
              style: {
                color: 'black',
              },
              disableUnderline: true,
              endAdornment: (
                <>
                  {
                    !isSm && (
                      <div>
                        {/* @ts-ignore */}
                        <Button
                          variant='contained'
                          disabled={ costAfterPromo !== cost }
                          style={ {
                            minWidth: '180px',
                            position: 'relative',
                            marginRight: '-14px',
                            color: 'white',
                            textTransform: 'none',
                          } }
                          color='secondary'
                          onClick={ applyPromoCode }
                        >
                          {t('reg_kit.review.common.coupon_use')}
                        </Button>
                      </div>
                    )
                  }
                </>
              ),
            } }
          />
        </Grid>

        {
          isSm && (
            <Grid
              item
              xs={ 12 }
              style={ {
                display: 'flex', justifyContent: 'center',
              } }
            >
              <Button
                variant='contained'
                disabled={ costAfterPromo !== cost }
                fullWidth
                style={ {
                  color: 'white',
                  textTransform: 'none',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  maxWidth: '222px',
                  marginTop: '5px',
                } }
                color='secondary'
                onClick={ applyPromoCode }
              >
                {t('reg_kit.review.common.coupon_use')}
              </Button>
            </Grid>
          )
        }

        <div style={ { display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', marginTop: '7px' } }>
          <hr style={ { width: '80%', height: '1px', background: '#f2f2f2', opacity: '60%', maxWidth: '600px' } } />
          <DialogTitle id='alert-dialog-title-review' style={ { paddingTop: 0, paddingBottom: 0 } }>
            { t('reg_kit.review.common.receipt_or_invoice') }
          </DialogTitle>
          <DialogContent style={ { overflowY: 'visible', paddingTop: 0 } }>
            <FormControl component='fieldset'>
              <RadioGroup
                value={ receiptType }
                onChange={ (e) => { setReceiptType(e.target.value); } }
                style={ { display: 'flex', flexDirection: 'row' } }
              >
                <FormControlLabel
                  value='receipt'
                  control={ <Radio /> }
                  style={ { textTransform: 'capitalize' } }
                  label={ t('reg_kit.review.common.receipt') }
                />
                <FormControlLabel
                  value='invoice'
                  style={ { textTransform: 'capitalize' } }
                  control={ <Radio /> }
                  label={ t('reg_kit.review.common.invoice') }
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
        </div>

        <Grid
          item
          xs={ 12 }
          style={ { display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' } }
        >
          <DialogTitle style={ { paddingBottom: 0, paddingTop: 0 } }>
            <Typography variant='h5' style={ { fontSize: '1.3rem' } }>
              { t('reg_kit.review.common.payment_method') }
            </Typography>
          </DialogTitle>

          <DialogContent style={ { paddingTop: 0 } }>
            <div style={ {
              display: 'flex',
              flexDirection: 'row',
              paddingTop: '0px',
              gap: '5px',
            } }
            >
              <div
                style={ {
                  // border: '1px solid rgba(0, 0, 0, .3)',
                  borderRadius: '5px',
                  minWidth: '215px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                } }
              >
                <FormControlLabel
                  value='debit-card'
                  control={ (
                    <Radio
                      checked={ 'debit-card' === paymentType }
                      onChange={ () => {
                        setPaymentType('debit-card');
                        setPaymentDetails((prev) => {
                          prev.installments = 0;
                          return { ...prev };
                        });
                      } }
                    />
                  ) }
                  label={ (
                    <div style={ { display: 'flex', alignItems: 'center', gap: '5px' } }>
                      {
                        t('reg_kit.review.common.debit_card')
                      }
                    </div>
                  ) }
                />
              </div>

              <div
                style={ {
                  // border: '1px solid rgba(0, 0, 0, .3)',
                  borderRadius: '5px',
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: '215px',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '5px',
                } }
              >
                <FormControlLabel
                  value='credit-card'
                  control={ (
                    <Radio
                      checked={ 'credit-card' === paymentType }
                      onChange={ () => { setPaymentType('credit-card'); } }
                    />
                  ) }
                  label={ t('reg_kit.review.common.credit_card') }
                />
                <Grid
                  item
                  xs={ 12 }
                  style={ {
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    marginLeft: '43px',
                  } }
                >
                  <FormControl
                    required
                    variant='outlined'
                    alignItems='center'
                    justifyContent='center'
                    style={ { minWidth: '158px' } }
                    size='small'
                  >
                    <InputLabel
                      color='secondary'
                      id='select-label-installments'
                      disabled={ 'debit-card' === paymentMethod }
                    >
                      {`${ t('reg_kit.review.non_doctor.pgx.installments') }`}
                    </InputLabel>
                    <Select
                      variant='outlined'
                      color='secondary'
                      labelId='select-label-installments'
                      disabled={ 'debit-card' === paymentMethod }
                      id='select-installments'
                      required
                      value={ paymentDetails.installments }
                      label={ `${ t('reg_kit.review.non_doctor.pgx.installments') }` }
                      onChange={ (e) => {
                        setPaymentDetails((prev) => {
                          prev.installments = e.target.value;
                          return { ...prev };
                        });
                      } }
                    >
                      <MenuItem value={ 0 }>{t('reg_kit.review.non_doctor.pgx.no_installments')}</MenuItem>
                      <MenuItem value={ 2 }>2</MenuItem>
                      <MenuItem value={ 3 }>3</MenuItem>
                      <MenuItem value={ 4 }>4</MenuItem>
                      <MenuItem value={ 5 }>5</MenuItem>
                      <MenuItem value={ 6 }>6</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </div>
            </div>
          </DialogContent>
        </Grid>

      </Grid>

      <>
        <hr style={ { width: '80%', background: '#f2f2f2', opacity: '60%', maxWidth: '600px' } } />
        <DialogTitle
          id='alert-dialog-title-review'
          style={ { paddingTop: 0, textAlign: 'center', paddingBottom: 0 } }
        >
          { t('Στοιχεία χρέωσης') }
        </DialogTitle>
        <DialogContent style={ { overflowY: 'visible' } }>
          <Grid container spacing={ 1 }>
            <Grid item xs={ 12 } md={ 6 }>
              <TextField
                value={ paymentDetails.first_name }
                variant='outlined'
                label={ t('common.first_name') }
                lettersOnly
                fullWidth
                inputProps={ { style: { textTransform: 'uppercase' } } }
                required
                error={ checkErrors && !validateField(paymentDetails.first_name) }
                helperText={ checkErrors && !validateField(paymentDetails.first_name) && t('validation.requiredField') }
                color='secondary'
                onChange={ (e) => {
                  setPaymentDetails((prev) => {
                    prev.first_name = e.target.value?.toUpperCase();
                    return { ...prev };
                  });
                } }
              />
            </Grid>

            <Grid item xs={ 12 } md={ 6 }>
              <TextField
                required
                value={ paymentDetails.last_name }
                variant='outlined'
                lettersOnly
                label={ t('common.last_name') }
                color='secondary'
                inputProps={ { style: { textTransform: 'uppercase' } } }
                error={ checkErrors && !validateField(paymentDetails.last_name) }
                helperText={ checkErrors && !validateField(paymentDetails.last_name) && t('validation.requiredField') }
                fullWidth
                onChange={ (e) => {
                  setPaymentDetails((prev) => {
                    prev.last_name = e.target.value?.toUpperCase();
                    return { ...prev };
                  });
                } }
              />
            </Grid>

            <Grid item xs={ 12 } md={ 6 }>
              <TextField
                value={ paymentDetails.billing_address }
                required
                variant='outlined'
                label={ t('common.street') }
                color='secondary'
                inputProps={ { style: { textTransform: 'uppercase' } } }
                error={ checkErrors && !validateField(paymentDetails.billing_address) }
                helperText={ checkErrors && !validateField(paymentDetails.billing_address) && t('validation.requiredField') }
                fullWidth
                onChange={ (e) => {
                  setPaymentDetails((prev) => {
                    prev.billing_address = e.target.value?.toUpperCase();
                    return { ...prev };
                  });
                } }
              />

            </Grid>

            <Grid item xs={ 12 } md={ 6 }>
              <TextField
                value={ paymentDetails.billing_street_number }
                variant='outlined'
                label={ t('common.number') }
                color='secondary'
                inputProps={ { style: { textTransform: 'uppercase' } } }
                fullWidth
                onChange={ (e) => {
                  setPaymentDetails((prev) => {
                    prev.billing_street_number = e.target.value?.toUpperCase();
                    return { ...prev };
                  });
                } }
              />
            </Grid>

            <Grid item xs={ 12 } md={ 6 } lg={ 3 }>
              <TextField
                value={ paymentDetails.billing_city }
                variant='outlined'
                label={ t('common.onlyCity') }
                error={ checkErrors && !validateField(paymentDetails.billing_city) }
                helperText={ checkErrors && !validateField(paymentDetails.billing_city) && t('validation.requiredField') }
                color='secondary'
                required
                inputProps={ { style: { textTransform: 'uppercase' } } }
                fullWidth
                onChange={ (e) => {
                  setPaymentDetails((prev) => {
                    prev.billing_city = e.target.value?.toUpperCase();
                    return { ...prev };
                  });
                } }
              />
            </Grid>

            <Grid item xs={ 12 } md={ 6 } lg={ 3 }>
              <CountySelectField
                name='county'
                defaultValue={ paymentDetails.billing_county }
                label={ t('common.county') }
                autoComplete='county'
                error={ checkErrors && !validateField(paymentDetails.billing_county) }
                helperText={ checkErrors && !validateField(paymentDetails.billing_county) && t('validation.requiredField') }
                required
                setExternalValue={ (value) => {
                  setPaymentDetails((prev) => {
                    prev.billing_county = value;
                    return { ...prev };
                  });
                } }
                readOnly={ false }
                loading={ false }
                disabled={ false }
              />
            </Grid>

            <Grid item xs={ 12 } md={ 6 } lg={ 3 }>
              <TextField
                value={ paymentDetails.billing_zip_code }
                variant='outlined'
                inputProps={ { style: { textTransform: 'uppercase' } } }
                label={ t('common.postal_code') }
                required
                color='secondary'
                error={ checkErrors && !validateField(paymentDetails.billing_zip_code) }
                helperText={ checkErrors && !validateField(paymentDetails.billing_zip_code) && t('validation.requiredField') }
                fullWidth
                onChange={ (e) => {
                  setPaymentDetails((prev) => {
                    prev.billing_zip_code = e.target.value;
                    return { ...prev };
                  });
                } }
              />
            </Grid>

            <Grid item xs={ 12 } md={ 6 } lg={ 3 }>
              <FormControl required fullWidth variant='outlined' color='secondary'>
                <InputLabel
                  color='secondary'
                  id='select-label-country'
                >
                  {t('country')}
                </InputLabel>
                <Select
                  variant='outlined'
                  color='secondary'
                  labelId='select-label-country'
                  required
                  value={ paymentDetails.billing_country }
                  label={ `${ t('common.country') } *` }
                  onChange={ (e) => {
                    setPaymentDetails((prev) => {
                      prev.billing_country = e.target.value;
                      return { ...prev };
                    });
                  } }
                >
                  {
                    countries.map((coun) => {
                      return (
                        <MenuItem
                          key={ coun.value }
                          value={ coun.value }
                        >
                          {t(coun.name)}
                        </MenuItem>
                      );
                    })
                  }
                </Select>
              </FormControl>
            </Grid>

            {
              'invoice' === receiptType && (
                <>
                  <Grid item xs={ 12 } md={ 6 }>
                    <TextField
                      value={ paymentDetails.billingBrandName }
                      variant='outlined'
                      label={ t('reg_kit.review.common.company_name') }
                      error={ checkErrors && !validateField(paymentDetails.billingBrandName) }
                      inputProps={ { style: { textTransform: 'uppercase' } } }
                      helperText={ checkErrors && !validateField(paymentDetails.billingBrandName) && t('validation.requiredField') }
                      color='secondary'
                      fullWidth
                      required
                      onChange={ (e) => {
                        setPaymentDetails((prev) => {
                          prev.billingBrandName = e.target.value?.toUpperCase();
                          return { ...prev };
                        });
                      } }
                    />
                  </Grid>

                  <Grid item xs={ 12 } md={ 6 }>
                    <TextField
                      value={ paymentDetails.billingCompanyActivity }
                      variant='outlined'
                      label={ t('reg_kit.review.common.company_activity') }
                      error={ checkErrors && !validateField(paymentDetails.billingCompanyActivity) }
                      helperText={ checkErrors && !validateField(paymentDetails.billingCompanyActivity) && t('validation.requiredField') }
                      inputProps={ { style: { textTransform: 'uppercase' } } }
                      color='secondary'
                      fullWidth
                      required
                      onChange={ (e) => {
                        setPaymentDetails((prev) => {
                          prev.billingCompanyActivity = e.target.value?.toUpperCase();
                          return { ...prev };
                        });
                      } }
                    />
                  </Grid>

                  <Grid item xs={ 12 } md={ 6 }>
                    <TextField
                      value={ paymentDetails.billingAfm }
                      variant='outlined'
                      label={ t('common.afm') }
                      error={ checkErrors && !validateField(paymentDetails.billingAfm) }
                      helperText={ checkErrors && !validateField(paymentDetails.billingAfm) && t('validation.requiredField') }
                      color='secondary'
                      fullWidth
                      required
                      onChange={ (e) => {
                        setPaymentDetails((prev) => {
                          prev.billingAfm = e.target.value;
                          return { ...prev };
                        });
                      } }
                    />
                  </Grid>

                  <Grid item xs={ 12 } md={ 6 }>
                    <TextField
                      value={ paymentDetails.billingDoy }
                      variant='outlined'
                      label={ t('reg_kit.review.common.doy') }
                      color='secondary'
                      fullWidth
                      onChange={ (e) => {
                        setPaymentDetails((prev) => {
                          prev.billingDoy = e.target.value;
                          return { ...prev };
                        });
                      } }
                    />
                  </Grid>
                </>
              )
            }
          </Grid>
        </DialogContent>

        <hr style={ { width: '80%', background: '#f2f2f2', opacity: '60%', maxWidth: '600px' } } />
        <DialogTitle
          id='alert-dialog-title-review'
          style={ { paddingTop: 0, paddingBottom: 0, textAlign: 'center' } }
        >
          { t('reg_kit.review.common.contact_information') }
        </DialogTitle>
        <DialogContent style={ { overflowY: 'visible' } }>

          <Grid container spacing={ 1 }>
            <Grid item xs={ 12 } md={ 6 }>
              <TextField
                value={ paymentDetails.customer_email }
                variant='outlined'
                label={ t('common.email') }
                error={ checkErrors && !validateField(paymentDetails.customer_email) }
                helperText={ checkErrors && !validateField(paymentDetails.customer_email) && t('validation.requiredField') }
                color='secondary'
                fullWidth
                required
                onChange={ (e) => {
                  setPaymentDetails((prev) => {
                    prev.customer_email = e.target.value;
                    return { ...prev };
                  });
                } }
              />
            </Grid>

            <Grid item xs={ 12 } md={ 6 }>
              <TextField
                value={ paymentDetails.customer_phone }
                variant='outlined'
                label={ t('phone') }
                color='secondary'
                fullWidth
                error={ checkErrors && !validateField(paymentDetails.customer_phone) }
                helperText={ checkErrors && !validateField(paymentDetails.customer_phone) && t('validation.requiredField') }
                required
                onChange={ (e) => {
                  setPaymentDetails((prev) => {
                    prev.customer_phone = e.target.value;
                    return { ...prev };
                  });
                } }
              />
            </Grid>
          </Grid>
        </DialogContent>

      </>

      <DialogActions style={ { position: 'relative', paddingTop: '5px' } }>

        {areDetailsMissing && 'bank' !== paymentType && (
          <span style={ { color: 'red',
            position: 'absolute',
            top: 0,
            right: 0,
            marginRight: '8px',
            marginTop: '3px' } }
          >
            *
            {t('Δεν έχουν συμπληρωθεί όλα τα στοιχεία')}
          </span>
        )}

        <Button
          variant='outlined'
          style={ { background: 'red', color: 'white' } }
          onClick={ () => {
            setSureDialogOpen(false);
          } }
        >
          {t('common.cancel')}
        </Button>

        <Button
          variant='contained'
          color='secondary'
          error={ areDetailsMissing }
          style={ { color: 'white' } }
          onClick={ async () => {
            if (!handleFormSubmit()) {
              return;
            }

            let xcostAfterPromo = costAfterPromo;
            let xPromoEntity = promoCodeEntity;

            if ('' !== promoCode && promoCode !== undefined && false === promoCodeApplied) {
              const xpromoCode = await applyPromoCode();
              if (xpromoCode === undefined) {
                return;
              }

              xcostAfterPromo = xpromoCode.amount;
              xPromoEntity = xpromoCode;
            }

            setSureDialogOpen(false);
            handlePGxKitSubmit(xcostAfterPromo, xPromoEntity);
          } }
        >
          {t('reg_kit.review.non_doctor.pgx.pay_kit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Payment;
