import {
  AiFillHome,
  BsPersonFill,
  IoIosSwitch,
  AiFillFolder,
  BsCreditCard2FrontFill,
  FaPen,
  FaFastForward,
  IoDocumentTextOutline,
  RiStethoscopeLine,
} from 'react-icons/all';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, { useMemo } from 'react';
import DoctorResultsPage from 'pages/DoctorResultsPage';
import PatientDetailsPage from 'pages/customer-kits-overview/pages/PatientDetailsPage';
import CorporateWelcomePage from 'pages/CorporateWelcomePage';
import CorporateRegisterAndViewResultsPage from 'pages/CorporateRegisterAndViewResultsPages';
import { corporateTheme } from 'theme/palette';
import CorporateProfilePage from 'pages/CorporateProfilePage';
import InviteADoctor from 'pages/InviteADoctor';
import { useAuth } from 'AuthCtx';
import useAPI from 'hooks/api-hooks';
import DoctorConsentsToCorporate from 'pages/customer-kits-overview/pages/DoctorConsentsToCorporate';
import CardBase from '../../app/CardBase';
import DoctorKitRegistrationDashboard, { DoctorKitRegistrationDashboardResume } from '../DoctorKitRegistrationDashboard';
import CorporateKitRegistrationDashboardAll from '../CorporateKitRegistrationDashboardAll';

const CardBaseWrapper = ({ children }) => {
  return (
    <CardBase withShadow align='left'>
      {children}
    </CardBase>
  );
};

const boxAroundIconCorporate = {
  // height: '10vh',
  // width: '10vh',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: corporateTheme.primary.light,
  border: corporateTheme.primary.light,
  boxShadow: '0px 0px 9px rgba(63, 78, 89, 0.46)',
  borderRadius: '10px',
};

/*
const DoctorGeneral = () => {
  const { userAttributes } = useAuth();
  const { t } = useTranslation();

  if (!userAttributes) {
    return null;
  }
  const verified = 'VERIFIED' === userAttributes.verificationStatus;

  if (!verified) {
    return (
      <Grid item xs={ 12 }>
        <Alert severity='info'>
          <AlertTitle>
            { t('Pending verification') }
          </AlertTitle>
          { t('Your registration is not yet completed. You will be notified with an email once completed.') }
        </Alert>
      </Grid>
    );
  }
  return (
    <NoDataPage title={ t('general') } />
  );
}; */

export const profileIconBig = () => {
  return (
    <div style={ boxAroundIconCorporate }>
      <AccountCircleIcon style={ { height: '50px', width: '50px', color: 'white' } } />
    </div>
  );
};

const registeredKitsIconBig = () => {
  return (
    <div style={ boxAroundIconCorporate }>
      <BsCreditCard2FrontFill style={ { height: '50px', width: '50px', color: 'white' } } />
    </div>
  );
};

const repeatIconBig = () => {
  return (
    <div style={ boxAroundIconCorporate }>
      <IoIosSwitch style={ { height: '50px', width: '50px', color: 'white' } } />
    </div>
  );
};

const resumeRegistrationKitsIconBig = () => {
  return (
    <div style={ boxAroundIconCorporate }>
      <FaFastForward style={ { height: '50px', width: '50px', color: 'white' } } />
    </div>
  );
};

const resultsDoctorIconBig = () => {
  return (
    <div style={ boxAroundIconCorporate }>
      <IoDocumentTextOutline style={ { height: '50px', width: '50px', color: 'white' } } />
    </div>
  );
};

const doctorsIconBig = () => {
  return (
    <div style={ boxAroundIconCorporate }>
      <img
        src='/doctors-50x50px.svg'
        alt=''
        style={ { filter: 'invert(100%) sepia(0%) saturate(5009%) hue-rotate(137deg) brightness(1000%) contrast(109%' } }
      />
    </div>
  );
};

const useCorporateNavigationPages = () => {
  const { client } = useAPI();
  const { databaseUser, databaseUserLoading } = useAuth(client);
  const isMotherBusiness = databaseUserLoading || null === databaseUser?.mother_business;

  const navigationPages = useMemo(() => {
    return [
      {
        id: 'general',
        title: 'home',
        Component: CorporateWelcomePage,
        wrapperComponent: null,
        Icon: AiFillHome,
        BigIcon: registeredKitsIconBig,
        type: 'item',
        isMenuHidden: false,
      },
      {
        id: 'patientDetails',
        title: 'patientDetails',
        Component: PatientDetailsPage,
        wrapperComponent: null,
        Icon: AiFillHome,
        type: 'item',
        isMenuHidden: true,
      },
      {
        id: 'patientQuestionnaire',
        title: 'patientQuestionnaire',
        Component: PatientDetailsPage,
        wrapperComponent: null,
        Icon: AiFillHome,
        type: 'item',
        isMenuHidden: true,
      },

      ...isMotherBusiness ? [
        {
          id: 'resultsDoctor',
          title: 'results',
          Component: DoctorResultsPage,
          wrapperComponent: CardBaseWrapper,
          Icon: IoDocumentTextOutline,
          type: 'item',
          BigIcon: resultsDoctorIconBig,
          isMenuHidden: false,
        },
      ] : [],

      {
        id: 'register-and-view-folder',
        title: 'Εγγραφή & αποτελέσματα ασθενών',
        Component: CorporateRegisterAndViewResultsPage,
        BigIcon: registeredKitsIconBig,
        wrapperComponent: CardBaseWrapper,
        Icon: AiFillFolder,
        type: 'folder',
        isMenuHidden: isMotherBusiness,
        items: [
          {
            id: 'registerNewKitDoctor',
            title: 'newKitRegistration',
            Component: CorporateKitRegistrationDashboardAll,
            wrapperComponent: CardBaseWrapper,
            Icon: FaPen,
            type: 'folder',
            BigIcon: registeredKitsIconBig,
            isMenuHidden: false,
            items: [
              {
                id: 'registerNewKitDoctorPGx',
                title: 'iDNA PGx CNS',
                Component: DoctorKitRegistrationDashboard,
                wrapperComponent: CardBaseWrapper,
                Icon: BsCreditCard2FrontFill,
                type: 'item',
                BigIcon: registeredKitsIconBig,
                isMenuHidden: false,
              },
              {
                id: 'registerNewKitDoctorCardio',
                title: 'iDNA Cardio Health',
                Component: DoctorKitRegistrationDashboard,
                wrapperComponent: CardBaseWrapper,
                Icon: BsCreditCard2FrontFill,
                type: 'item',
                BigIcon: registeredKitsIconBig,
                isMenuHidden: false,
              },
              {
                id: 'registerNewKitDoctorNutrigenetixLabs',
                title: 'iDNA NutriGenetix',
                Component: DoctorKitRegistrationDashboard,
                wrapperComponent: CardBaseWrapper,
                Icon: BsCreditCard2FrontFill,
                type: 'item',
                BigIcon: repeatIconBig,
                isMenuHidden: false,
              },
            ],
          },
          {
            id: 'resumeRegisterKitDoctor',
            title: 'resumeKitRegistration',
            Component: DoctorKitRegistrationDashboardResume,
            wrapperComponent: CardBaseWrapper,
            Icon: FaFastForward,
            type: 'item',
            BigIcon: resumeRegistrationKitsIconBig,
            isMenuHidden: isMotherBusiness,
          },
          {
            id: 'resultsDoctor',
            title: 'results',
            Component: DoctorResultsPage,
            wrapperComponent: CardBaseWrapper,
            Icon: IoDocumentTextOutline,
            type: 'item',
            BigIcon: resultsDoctorIconBig,
            isMenuHidden: false,
          },
        ],
      },
      {
        id: 'doctorsHealthProfessionals',
        title: 'invitations',
        Component: DoctorConsentsToCorporate,
        wrapperComponent: CardBaseWrapper,
        Icon: RiStethoscopeLine,
        BigIcon: doctorsIconBig,
        alternativeIds: [ 'invite-a-doctor' ],
        type: 'item',
        isMenuHidden: isMotherBusiness,
      },
      {
        id: 'invite-a-doctor',
        title: 'inviteADoctor',
        Component: InviteADoctor,
        wrapperComponent: CardBaseWrapper,
        type: 'item',
        isMenuHidden: true,
      },
      {
        id: 'profile',
        title: 'profile',
        Component: CorporateProfilePage,
        wrapperComponent: CardBaseWrapper,
        BigIcon: profileIconBig,
        Icon: BsPersonFill,
        type: 'item',
        isMenuHidden: false,
      },
    ];
  }, [ isMotherBusiness ]);

  return navigationPages;
};

export default useCorporateNavigationPages;

