import { Button, Container, Dialog, DialogContent, Grid, Divider } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { useItems } from 'models/atoms/items-hook';
import * as _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import AlertTitle from '@material-ui/lab/AlertTitle';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import useAPI from 'hooks/api-hooks';
import { useAuth } from 'AuthCtx';
import SelectReportLanguageForm from 'components/forms/SelectReportLanguageForm';
import DoctorGeneralConsentWellnessForm from 'components/forms/wellness-forms/DoctorGeneralConsentWellnessForm';
import DoctorGeneralConsentWellnessFormPGx from 'components/forms/wellness-forms/DoctorGeneralConsentWellnessFormPGx';
import { RepeatQuestionnairePage } from 'pages/RepeatRegister/RepeatNewQuestionnaire';
import PatientDetailsWithHealthcareProfessional from 'components/forms/wellness-forms/PatientDetailsWithHealthcareProfessional';
import { corporateTheme } from 'theme/palette';
import Questionnaire, { useStyles as useStylesQuestionnaire } from './Questionnaire';
import SectionAlternate from '../app/SectionAlternate';
import { NavigationProvider, useFetching, useNavigation, useFallBackPage } from '../../NavigationProvider';
import UserDetailsForm from '../forms/common-forms/UserDetailsForm';
import PatientDetailsForm from '../forms/common-forms/PatientDetailsForm';
import SerialNumberRegistrationForm from '../forms/common-forms/SerialNumberRegistrationForm';
import GeneralConsentPGXForm from '../forms/pgx-forms/GeneralConsentPGXForm';
import DoctorDetailsForm from '../forms/pgx-forms/DoctorDetailsForm';
import { useKit, useKits } from '../../models/kits';
import KitsList from '../KitsList';
import SampleCollectionForm from '../forms/common-forms/SampleCollectionForm';
import FormSectionSubmit from '../forms/atoms/FormSectionSubmit';
import HealthcareProfessionalDetailsForm from '../forms/wellness-forms/HealthcareProfessionalDetailsForm';
import GeneralConsentWellnessForm from '../forms/wellness-forms/GeneralConsentWellnessForm';
import UserDetailsWithHealthcareProfessional from '../forms/wellness-forms/UserDetailsWithHealthcareProfessional';
// import reportSvg from '../../assets/report.svg';
import CardBase from '../app/CardBase';
import KitRegistrationSteps from './KitRegistrationSteps';
import KitRegistrationAnswersReview from './KitRegistrationAnswersReview';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: '100%',
      width: '100%',
    },
    root_1: {
      fontSize: '20px',
      fontFamily: 'Roboto',
      lineHeight: '21px',
      letterSpacing: '.04em',
      color: theme.palette.secondary.main,
      textAlign: 'initial',
    },
    section: {
      '& .section-alternate__content': {
        paddingTop: 0,
        marginTop: theme.spacing(2),
        position: 'relative',
        zIndex: 1,
      },
      '& .card-base__content': {
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(3),
        },
      },
    },
    successmsg: {
      // fontWeight: 'bold',
      fontSize: '20px',
      fontFamily: 'Roboto',
      lineHeight: '21px',
      letterSpacing: '.04em',
      color: theme.palette.secondary.main,
    },
  };
});

const UserDetailsPage = (props) => {
  const { t } = useTranslation();

  const { child } = useKit(props.kitId);

  return (
    <>
      <Container>
        <Alert
          severity='info'
          style={ {
            textAlign: 'initial',
          } }
        >
          {null == child ? t('reg_kit.personal_data.adult.non_bioage.personal_data_info') : t('reg_kit.personal_data.child.personal_data_info')}
        </Alert>
      </Container>
      <UserDetailsForm { ...props } />
    </>
  );
};

const PatientDetailsPage = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <Alert
          severity='info'
          style={ {
            textAlign: 'initial',
          } }
        >
          {t('reg_kit.personal_data.doctor.non_bioage.personal_data_info')}
        </Alert>
      </Container>
      <PatientDetailsForm { ...props } />
    </>
  );
};

const PatientDetailsPageBioAge = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <Alert
          severity='info'
          style={ {
            textAlign: 'initial',
          } }
        >
          {t('reg_kit.personal_data.doctor.bioage.personal_data_info')}
        </Alert>
      </Container>
      <PatientDetailsForm { ...props } />
    </>
  );
};

// eslint-disable-next-line no-unused-vars
const HealthcareProfessionalDetailsPage = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <Alert severity='info'>
          {t('healthcareProfessionalInvitationPrompt')}
        </Alert>
      </Container>
      <HealthcareProfessionalDetailsForm { ...props } />
    </>
  );
};

const UserDetailsWithHealthcareProfessionalDetailsPage = (props) => {
  return (
    <>
      <UserDetailsWithHealthcareProfessional { ...props } />
    </>
  );
};

const DoctorDetailsPage = (props) => {
  const { t } = useTranslation();
  const [ openDialog, setOpenDialog ] = useState(false);

  const { isCorporate } = useAuth();

  const { kitMetadata } = useKit(props.kitId);

  const theme = useTheme();

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Container>
        <Alert
          severity='info'
          style={ { textAlign: 'initial' } }
        >
          {
            (null == kitMetadata?.referenceNumber || '' === kitMetadata?.referenceNumber) && (
              <>
                {
                  isCorporate
                    ? t('reg_kit.doc_personal_data.common.info_msg_corp')
                    : t('reg_kit.doc_personal_data.common.info_msg')
                }
                <br />
              </>
            )

          }
          <div style={ { display: 'inline', textAlign: 'initial' } }>
            {t('reg_kit.doc_personal_data.common.read_more')}
            {' '}
            <Button
              size='small'
              style={ { color: isCorporate ? corporateTheme.primary.main : '#94c320', textTransform: 'none', padding: 0, justifyContent: 'left', minWidth: 'unset' } }
              onClick={ handleDialogOpen }
            >
              {t('common.here')}
            </Button>
          </div>
        </Alert>
        <Dialog keepMounted={ false } open={ openDialog } onClose={ handleDialogClose }>
          <DialogContent style={ { textAlign: 'initial' } }>
            {
              isCorporate
                ? t('reg_kit.doc_personal_data.common.pharm_analysis_benefits_corp')
                : t('reg_kit.doc_personal_data.common.pharm_analysis_benefits')
            }
          </DialogContent>
          <DialogContent>
            <div style={ { display: 'flex', justifyContent: 'center' } }>
              <Button
                variant='outlined'
                style={ { borderColor: isCorporate ? corporateTheme.primary.main : theme.palette.success.main } }
                onClick={ handleDialogClose }
              >
                {t('common.close')}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </Container>
      <DoctorDetailsForm { ...props } />
    </>
  );
};

/*
const ReportSvg = () => {
  return (
    <img
      alt='follow-instructions'
      width='20px'
      height='auto'
      src={ reportSvg }
    />
  );
}; */

const SuccessfulKitRegistrationPage = (
  {
    onSubmit,
    onError,
    kitId,
    goToPrevPage,
  },
) => {
  const { t } = useTranslation();

  const { isLoading, isKitPGX, isKitWellness, data, child } = useKit(kitId);

  const country = data?.customer_id?.country;

  const isUS = 'US' === country;

  const [ submitting, setSubmitting ] = useState(false);

  const { isDoctor, isCorporate } = useAuth();

  const classes = useStyles();

  let alertContent;

  if (null != child) {
    if (isUS) {
      alertContent = t('reg_kit.results_receipt.child.report_availiable_us');
    } else {
      alertContent = t('reg_kit.results_receipt.child.report_availiable');
    }
  } else if ((isDoctor || isCorporate) && !isKitPGX) {
    if (isUS) {
      alertContent = t('reg_kit.results_receipt.doctor.non_pgx_non_bioage.report_availiable_us');
    } else {
      alertContent = t('reg_kit.results_receipt.doctor.non_pgx_non_bioage.report_availiable');
    }
  } else if ((isDoctor || isCorporate) && isKitPGX) {
    if (isUS) {
      alertContent = t('reg_kit.results_receipt.doctor.pgx.report_availiable_us');
    } else {
      alertContent = t('reg_kit.results_receipt.doctor.pgx.report_availiable');
    }
  } else if (isKitPGX) {
    if (isUS) {
      alertContent = t('reg_kit.results_receipt.adult.pgx.report_availiable_us');
    } else {
      alertContent = t('reg_kit.results_receipt.adult.pgx.report_availiable');
    }
  } else if (isKitWellness) {
    if (isUS) {
      alertContent = t('reg_kit.results_receipt.adult.non_pgx_non_bioage.report_availiable_us');
    } else {
      alertContent = t('reg_kit.results_receipt.adult.non_pgx_non_bioage.report_availiable');
    }
  }

  if (isLoading) {
    return <p>Loading</p>;
  }

  return (
    <Grid>
      <Container>
        <Alert
          severity='info'
          color='rgba(0, 0, 0, 0)'
          style={ { textAlign: 'initial' } }
          icon={ (
            <Grid />
          ) }
        >
          <AlertTitle
            className={ classes.root_1 }
            style={ { textAlign: 'initial' } }
          >
            {t('reg_kit.results_receipt.common.results_receipt_title')}
            <Divider style={ { marginTop: '4px' } } />
          </AlertTitle>
          {alertContent}
        </Alert>
      </Container>
      <form
        noValidate
        onSubmit={ (e) => {
          e.preventDefault();
          return Promise.resolve()
            .then(() => {
              setSubmitting(true);
            })
            .then(() => {
              onSubmit();
            })
            .then(() => {
              setSubmitting(false);
            })
            .catch(() => {
              onError();
            });
        } }
        style={ { padding: '24px' } }
      >
        <FormSectionSubmit
          label={ t('common.next') }
          loading={ submitting }
          goToPrevPage={ goToPrevPage }
          color={ isDoctor ? '#526D9C' : undefined }
        />
      </form>
    </Grid>
  );
};

SuccessfulKitRegistrationPage.propTypes = {
  kitId: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

SuccessfulKitRegistrationPage.defaultProps = {
  onSubmit: () => { return null; },
  onError: () => { return null; },
};

export const NutriBioSuccessfullRegistrationPage = ({
  onSubmit,
  onError,
  kitId,
  goToPrevPage,
  insideOtherPage = undefined,
  otherPageSubmit = undefined,
  newQuestionnaire = false,
}) => {
  const { t } = useTranslation();

  const [ formData, setFormData ] = useState({});

  const [ performScroll, setPerformScroll ] = useState(0);

  const [ correctValues, setCorrectValues ] = useState({});

  const { isDoctor, isCorporate, userAttributes } = useAuth();

  const { isLoading, questionnaireFormId, serial, questions, categoryId, data, isKitBioAge, child } = useKit(kitId);
  const [ submitting, setSubmitting ] = useState(false);

  const customerBirthdate = data?.customer_id?.birthdate;
  const customerGender = data?.customer_id?.gender;
  let customerCountry = data?.customer_id?.country;
  if (null != child) {
    customerCountry = child?.country;
  }

  const [ questionnaireError, setQuestionnaireError ] = useState('');

  const [ hasTriedSubmitting, setHasTriedSubmitting ] = useState(false);

  const questClasses = useStylesQuestionnaire();

  const qSource = `https://docs.google.com/forms/d/e/${ questionnaireFormId }/viewform`;

  const buttonlbl = t('common.save');

  const [ emptyAnswerId, setEmptyAnswerId ] = useState(-1);

  const [ wrongAnswerIdState, setWrongAnswerIdState ] = useState(-1);

  let wrongAnswerId = -1;

  const { client } = useAPI();

  const queryClient = useQueryClient();

  const {
    items: questiondata,
    itemsLoading: questiondataLoading,
  } = useItems(`customer-questionnaire-answers?serial_number.serial=${ serial }`);

  const hasAnwsweredQuestionnaire = newQuestionnaire
  || questiondataLoading || null == questiondata || 0 === questiondata.length
    || null == questiondata[0] || null == questiondata[0].value;

  useEffect(() => {
    const copy = formData;
    const otherCopy = correctValues;

    if (questiondataLoading || isLoading) { return; }

    questions.forEach((item) => {
      const index = item.id;

      otherCopy[index] = true;

      if (null == questiondata || 0 === questiondata.length || null == questiondata[0] || null == questiondata[0].value || newQuestionnaire) {
        if ('multiple_choice' === item.type) {
          copy[index] = [];
        } else if ('date' === item.type) {
          copy[index] = null;
        } else if ('date_integer' === item.type) {
          copy[index] = null;
          copy[`${ Number(index) + 1 }`] = '';
        } else {
          copy[index] = '';
        }
      }
    });

    if (!(hasAnwsweredQuestionnaire)) {
      const prevData = _.cloneDeep(questiondata[0].value);
      delete prevData['0'];
      delete prevData['1'];
      delete prevData['2'];

      let skipNext = false;
      let offset = 0;

      const objData = {};

      Object.keys(prevData).forEach((key) => {
        if (!skipNext && '1' !== key && '2' !== key) {
          const newKey = `${ Number(key) - offset - 2 }`;
          const keyNum = Number(key) - offset - 3;

          if (questions.length > keyNum && ('three_integers' === questions[keyNum]?.type || 'two_floats' === questions[keyNum]?.type)) {
            offset += 1;
            skipNext = true;
            objData[newKey] = `${ prevData[key] }/${ prevData[Number(key) + 1] }`;
          } else {
            objData[newKey] = prevData[key];
          }
        } else {
          skipNext = false;
        }
      });

      setFormData(objData);
    } else {
      setFormData(copy);
    }

    setCorrectValues(otherCopy);
  }, [ questiondataLoading, isLoading ]);

  useEffect(() => {
    if (-2 === emptyAnswerId) {
      setQuestionnaireError('reg_kit.questionnaire.common.invalid_answers');
    } else if (-1 !== emptyAnswerId) {
      setQuestionnaireError('reg_kit.questionnaire.common.missing_answers_error');
    } else {
      setQuestionnaireError('');
    }
  }, [ emptyAnswerId ]);

  useEffect(() => {
    if (hasTriedSubmitting) {
      checkValidQuestionnaire(false);
    }
  }, [ formData, correctValues ]);

  const checkValidQuestionnaire = () => {
    const formDataEntr = Object.entries(formData);

    let hasEmpty = true;

    const correctValuesEntr = Object.entries(correctValues);

    let areAllValuesCorrect = false;
    let noWrongAnswer = true;

    if (0 < correctValuesEntr.length) {
      areAllValuesCorrect = correctValuesEntr.every((item) => {
        const value = true === item[1];

        if (noWrongAnswer && false === value) {
          noWrongAnswer = false;
          wrongAnswerId = (Number(item[0]));
          setWrongAnswerIdState(wrongAnswerId);
        }

        return value;
      });
    }

    if (!areAllValuesCorrect) {
      setEmptyAnswerId(-2);
    }

    const setEmptyAnswerIdFunc = (val) => {
      const numVal = Number(val);

      if (numVal < wrongAnswerId || -1 === wrongAnswerId) {
        setEmptyAnswerId(numVal);
      } else {
        setEmptyAnswerId(-2);
      }
    };

    if (0 < formDataEntr.length) {
      let noAnswer = true;

      hasEmpty = formDataEntr.some((item) => {
        const question = questions.find((q) => {
          return q.id === item[0];
        });

        if (question === undefined || (false === question.required && question.referral === undefined)) {
          return false;
        }

        if (question.referral !== undefined) {
          const referralQuestion = questions.find((q) => {
            return q.id === question.referral;
          });

          if (referralQuestion === undefined) {
            return false;
          }

          if (!referralQuestion.referrence.options.includes(formData[question.referral])) {
            return false;
          }
        }

        if ('three_integers' === question.type) {
          const values = item[1]?.split('/');
          if (null == values || 3 !== values.length || '' === values[0] || '' === values[1] || '' === values[2]) {
            if (noAnswer) {
              noAnswer = false;
              setEmptyAnswerIdFunc(item[0]);
            }

            return true;
          }

          return false;
        }

        if ('two_floats' === question.type) {
          const values = item[1]?.split('/');
          if (null == values || 1 === values.length || 0 === values.length || '' === values[0] || '' === values[1]) {
            if (noAnswer) {
              noAnswer = false;
              setEmptyAnswerIdFunc(item[0]);
            }

            return true;
          }

          return false;
        }

        if ('date_integer' === question.type && 'multiple' === question.times) {
          const start = Number(question.id);
          const numOfItems = Math.ceil((formDataEntr.length - start + 1) / 2);

          if (0 === numOfItems) {
            if (noAnswer) {
              noAnswer = false;
              setEmptyAnswerIdFunc(item[0]);
            }
            return true;
          }

          // At least one with error.
          let atLeastOne = false;

          for (let i = start; i < start + numOfItems * 2; i += 2) {
            if (null == formDataEntr[i - 1] || null === formDataEntr[i - 1][1]
              || null == formDataEntr[i] || '' === formDataEntr[i][1]) {
              atLeastOne = true;
              break;
            }
          }

          if (atLeastOne && noAnswer) {
            noAnswer = false;
            setEmptyAnswerIdFunc(item[0]);
          }

          return atLeastOne;
        }

        const val = '' === item[1] || null === item[1] || 0 === item[1].length;

        if (true === val && noAnswer) {
          noAnswer = false;
          setEmptyAnswerIdFunc(item[0]);
        }

        return val;
      });
    } else {
      setEmptyAnswerId(1);
    }

    if (!hasEmpty && areAllValuesCorrect) {
      setEmptyAnswerId(-1);
    }

    return (!hasEmpty && areAllValuesCorrect);
  };

  const resetQueries = () => {
    queryClient.invalidateQueries(
      [
        `customer-questionnaire-answers?serial_number.serial=${ serial }`,
        {
          page: 0,
          limit: 10,
          sort: {
            order: 'desc',
            orderBy: 'updated_at',
          },
        },
      ],
    );

    queryClient.invalidateQueries(
      [
        `customer-questionnaire-answers?serial_number.serial=${ serial }`,
        'COUNT',
        {},
      ],
    );

    queryClient.invalidateQueries(
      [
        `customer-questionnaire-answers?serial_number.serial=${ serial }`,
        'COUNT',
      ],
    );
  };

  const handleKitSubmit = (e) => {
    e?.preventDefault();

    const isQuestValid = checkValidQuestionnaire();

    if (!isQuestValid) {
      setHasTriedSubmitting(true);
      setPerformScroll((prev) => { return prev + 1; });
      return null;
    }

    return Promise.resolve()
      .then(() => {
        setSubmitting(true);
      })
      .then(() => {
        const { gender } = userAttributes;

        if (isQuestValid) {
          const fullFormData = {
            0: serial,
            1: (isDoctor || isCorporate) ? customerBirthdate : null != child ? child.birthdate : userAttributes.dateOfBirth,
            2: (isDoctor || isCorporate) ? customerGender : null != child ? child.gender : gender,
          };

          Object.entries(formData).forEach((d) => {
          // eslint-disable-next-line prefer-destructuring
            fullFormData[Number(d[0]) + 2] = d[1];
          });

          let offset = 0;

          Object.entries(formData).forEach((d) => {
            const isThreeIntegers = 'three_integers' === questions.find((quest) => {
              return quest.id === d[0];
            })?.type;

            const isTwoFloats = 'two_floats' === questions.find((quest) => {
              return quest.id === d[0];
            })?.type;

            if (isThreeIntegers) {
              const values = d[1].split('/');

              // eslint-disable-next-line prefer-destructuring
              fullFormData[Number(d[0]) + 2 + offset] = values[0];
              offset += 1;

              // eslint-disable-next-line prefer-destructuring
              fullFormData[Number(d[0]) + 2 + offset] = `${ values[1] }/${ values[2] }`;
            } else if (isTwoFloats) {
              const values = d[1].split('/');

              // eslint-disable-next-line prefer-destructuring
              fullFormData[Number(d[0]) + 2 + offset] = values[0];
              offset += 1;

              // eslint-disable-next-line prefer-destructuring
              fullFormData[Number(d[0]) + 2 + offset] = `${ values[1] }`;
            } else {
            // eslint-disable-next-line prefer-destructuring
              fullFormData[Number(d[0]) + 2 + offset] = d[1];
            }
          });

          if (null === questiondata || 0 === questiondata.length || newQuestionnaire) {
            return client.post('customer-questionnaire-answers', { ...fullFormData }).then(() => {
              resetQueries();
            });
          }
          return client.put(`customer-questionnaire-answers/${ serial }`, { ...fullFormData }).then(() => {
            resetQueries();
          });
        }

        return null;
      })
      .then(() => {
        if (otherPageSubmit !== undefined) {
          return otherPageSubmit();
        }

        return null;
      })
      .then(() => {
        setSubmitting(false);
        return onSubmit();
      })
      .catch(() => {
        onError();
      });
  };

  // eslint-disable-next-line no-constant-condition
  if (isLoading) {
    return (
      <div style={ { width: '100%', height: '100%' } }>
        <LoaderSkeleton />
      </div>
    );
  }

  // if (false === areQuestionsFilled) {
  //   finalButtonDisabled = true;
  // }

  // if (true === areQuestionsFilled) {
  //   finalButtonDisabled = false;
  //   buttonlbl = t('complete');
  // }

  return (
    <Grid>
      <QuestionnaireFormPage
        kitId={ kitId }
        insideOtherPage={ insideOtherPage }
        wrongAnswerId={ wrongAnswerIdState }
        performScroll={ performScroll }
        questiondataLoading={ hasAnwsweredQuestionnaire }
        emptyAnswerId={ emptyAnswerId }
        correctValues={ correctValues }
        setCorrectValues={ setCorrectValues }
        questions={ questions }
        formData={ formData }
        setFormData={ setFormData }
        serial={ serial }
        source={ qSource }
        prevData={ questiondata }
        categoryId={ categoryId }
        customerCountry={ customerCountry }
      />

      {/* { !isKitBioAge */}
      {/* ? ( */}

      {
        isKitBioAge && (
          <div
            className={ questClasses.question }
            style={ { paddingBottom: '24px' } }
          >
            <Alert
              severity='warning'
              className={ questClasses.alert }
            >
              <AlertTitle
                style={ { fontWeight: 'bold' } }
              >
                {t('reg_kit.questionnaire.bioage.please_check_submitted_data')}
              </AlertTitle>
            </Alert>

          </div>
        )
      }

      <div
        // <form
        // noValidate
        // onSubmit={ handleKitSubmit }
        style={ { padding: '24px' } }
      >
        <FormSectionSubmit
          label={ buttonlbl }
          doSubmit={ handleKitSubmit }
          disabled={ false }
          loading={ submitting || questiondataLoading }
          error={ questionnaireError }
          goToPrevPage={ goToPrevPage }
          color={ isDoctor ? '#526D9C' : undefined }
        />
      </div>
      {/* </form> */}
      {/* ) : (
          <form
            noValidate
            style={ { padding: '24px' } }
            onSubmit={ handleNext }
          >
            <FormSectionSubmit
              label={ buttonlbl }
              loading={ submitting || resultsLoading }
              disabled={ finalButtonDisabled }
            />
          </form>
        )} */}

    </Grid>
  );
};

NutriBioSuccessfullRegistrationPage.propTypes = {
  kitId: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

NutriBioSuccessfullRegistrationPage.defaultProps = {
  onSubmit: () => { return null; },
  onError: () => { return null; },
};

const SampleCollectionPage = (props) => {
  return (
    <>
      <SampleCollectionForm { ...props } />
    </>
  );
};

const GeneralConsentPGXFormPage = (props) => {
  return (
    <GeneralConsentPGXForm
      href='./assets/pdfs/GDPR_PGX_PERSONAL_DATA.pdf'
      { ...props }
    />
  );
};
const GeneralConsentWellnessFormPage = (props) => {
  return (
    <GeneralConsentWellnessForm
      href='./assets/pdfs/Updated_Wellness_GDPR.pdf'
      href_en='./assets/pdfs/GDPR_Consents Wellness ENG.pdf'
      { ...props }
    />
  );
};

const DoctorGeneralConsentWellnessFormPage = (props) => {
  return (
    <DoctorGeneralConsentWellnessForm
      { ...props }
    />
  );
};

const GeneralConsentBioAgeFormPage = (props) => {
  return (
    <GeneralConsentWellnessForm
      href='./assets/pdfs/GDPR_Biological_Age.pdf'
      href_en='./assets/pdfs/GDPR+Biological+Age_Consents+Wellness+ENG.pdf'
      { ...props }
    />
  );
};

const QuestionnaireFormPage = (props) => {
  if (null == props.questions || !('1' in props.formData)) {
    return <LoaderSkeleton />;
  }

  return (
    <Questionnaire
      { ...props }
    />
    // <iframe
    //   id='gForm'
    //   title='Ερωτηματολόγιο'
    //   src={ props.source }
    //   // onLoad={ () => { props.disableBtnfn(props.disableBtnvar + 1); } }
    //   width='100%'
    //   height={ 2 === props.disableBtnvar ? '310px' : '1100px' }
    //   style={ { backgroundColor: '#F0F0F0' } }
    // />
  );
};

const pgxRegistrationPages = [
  {
    id: 'select-report-language',
    label: 'reg_kit.select_report_locale.common.title',
    component: SelectReportLanguageForm,
  },
  {
    id: 'user-details',
    label: 'reg_kit.personal_data.common.title',
    component: UserDetailsPage,
  },
  {
    id: 'doctor-details',
    label: 'doctorDetails',
    component: DoctorDetailsPage,
  },
  {
    id: 'general-consent-pgx',
    label: 'reg_kit.consents.common.title',
    component: GeneralConsentPGXFormPage,
  },
  {
    id: 'sample-collection',
    label: 'reg_kit.sample.common.title',
    component: SampleCollectionPage,
  },
  {
    id: 'kit-registration-answers-review',
    label: 'Έλεγχος και οριστικοποίηση εγγραφής',
    component: KitRegistrationAnswersReview,
  },
];

const doctorPGxRegistrationPages = [
  {
    id: 'select-report-language',
    label: 'reg_kit.select_report_locale.common.title',
    component: SelectReportLanguageForm,
  },
  {
    id: 'user-details',
    label: 'reg_kit.personal_data.common.title',
    component: PatientDetailsPage,
  },
  {
    id: 'general-consent-pgx',
    label: 'reg_kit.consents.common.title',
    component: DoctorGeneralConsentWellnessFormPGx,
  },
  {
    id: 'sample-collection',
    label: 'reg_kit.sample.common.title',
    component: SampleCollectionPage,
  },
  {
    id: 'kit-registration-answers-review',
    label: 'Έλεγχος και οριστικοποίηση εγγραφής',
    component: KitRegistrationAnswersReview,
  },
];

const corporatePGxRegistrationPages = [
  {
    id: 'select-report-language',
    label: 'reg_kit.select_report_locale.common.title',
    component: SelectReportLanguageForm,
  },
  {
    id: 'user-details',
    label: 'reg_kit.personal_data.adult.pgx.personal_data_title',
    component: PatientDetailsWithHealthcareProfessional,
  },
  {
    id: 'doctor-details',
    label: 'doctorDetails',
    component: DoctorDetailsPage,
  },
  {
    id: 'general-consent-pgx',
    label: 'reg_kit.consents.common.title',
    component: DoctorGeneralConsentWellnessFormPGx,
  },
  {
    id: 'sample-collection',
    label: 'reg_kit.sample.common.title',
    component: SampleCollectionPage,
  },
  {
    id: 'kit-registration-answers-review',
    label: 'Έλεγχος και οριστικοποίηση εγγραφής',
    component: KitRegistrationAnswersReview,
  },
];

const wellnessRegistrationPages = [
  {
    id: 'select-report-language',
    label: 'reg_kit.select_report_locale.common.title',
    component: SelectReportLanguageForm,
  },
  {
    id: 'user-details',
    label: 'reg_kit.personal_data.common.title',
    component: UserDetailsWithHealthcareProfessionalDetailsPage,
  },
  {
    id: 'general-consent-wellness',
    label: 'reg_kit.consents.common.title',
    component: GeneralConsentWellnessFormPage,
  },
  {
    id: 'sample-collection',
    label: 'reg_kit.sample.common.title',
    component: SampleCollectionPage,
  },
  {
    id: 'kit-registration-answers-review',
    label: 'Έλεγχος και οριστικοποίηση εγγραφής',
    component: KitRegistrationAnswersReview,
  },
];

const doctorWellnessRegistrationPages = [
  {
    id: 'select-report-language',
    label: 'reg_kit.select_report_locale.common.title',
    component: SelectReportLanguageForm,
  },
  {
    id: 'user-details',
    label: 'reg_kit.personal_data.common.title',
    component: PatientDetailsPage,
  },
  {
    id: 'general-consent-wellness',
    label: 'reg_kit.consents.common.title',
    component: DoctorGeneralConsentWellnessFormPage,
  },
  {
    id: 'sample-collection',
    label: 'reg_kit.sample.common.title',
    component: SampleCollectionPage,
  },
  {
    id: 'kit-registration-answers-review',
    label: 'Έλεγχος και οριστικοποίηση εγγραφής',
    component: KitRegistrationAnswersReview,
  },
];

const doctorUpgradeWellnessRegistrationPages = [
  {
    id: 'select-report-language',
    label: 'reg_kit.select_report_locale.common.title',
    component: SelectReportLanguageForm,
  },
  {
    id: 'user-details',
    label: 'reg_kit.personal_data.common.title',
    component: PatientDetailsPage,
  },
  {
    id: 'general-consent-wellness',
    label: 'reg_kit.consents.common.title',
    component: DoctorGeneralConsentWellnessFormPage,
  },
  {
    id: 'kit-registration-answers-review',
    label: 'Έλεγχος και οριστικοποίηση εγγραφής',
    component: KitRegistrationAnswersReview,
  },
];

const doctorNutrigenixRegistrationPages = [
  {
    id: 'select-report-language',
    label: 'reg_kit.select_report_locale.common.title',
    component: SelectReportLanguageForm,
  },
  {
    id: 'user-details',
    label: 'reg_kit.personal_data.common.doc_title',
    component: PatientDetailsPage,
  },
  {
    id: 'general-consent-wellness',
    label: 'reg_kit.consents.common.title',
    component: DoctorGeneralConsentWellnessFormPage,

  },
  {
    id: 'sample-collection',
    label: 'reg_kit.sample.common.title',
    component: SampleCollectionPage,
  },
  {
    id: 'successful-completion',
    label: 'reg_kit.questionnaire.common.title',
    component: NutriBioSuccessfullRegistrationPage,
  },
  {
    id: 'kit-registration-answers-review',
    label: 'Έλεγχος και οριστικοποίηση εγγραφής',
    component: KitRegistrationAnswersReview,
  },
];

const corporateNutrigenixRegistrationPages = [
  {
    id: 'select-report-language',
    label: 'reg_kit.select_report_locale.common.title',
    component: SelectReportLanguageForm,
  },
  {
    id: 'user-details',
    label: 'reg_kit.personal_data.common.doc_title',
    component: PatientDetailsWithHealthcareProfessional,
  },
  {
    id: 'general-consent-wellness',
    label: 'reg_kit.consents.common.title',
    component: DoctorGeneralConsentWellnessFormPage,
  },
  {
    id: 'sample-collection',
    label: 'reg_kit.sample.common.title',
    component: SampleCollectionPage,
  },
  {
    id: 'successful-completion',
    label: 'reg_kit.questionnaire.common.title',
    component: NutriBioSuccessfullRegistrationPage,
  },
  {
    id: 'kit-registration-answers-review',
    label: 'Έλεγχος και οριστικοποίηση εγγραφής',
    component: KitRegistrationAnswersReview,
  },
];

const doctorUpgradeNutrigenixRegistrationPages = [
  {
    id: 'select-report-language',
    label: 'reg_kit.select_report_locale.common.title',
    component: SelectReportLanguageForm,
  },
  {
    id: 'user-details',
    label: 'reg_kit.personal_data.common.title',
    component: PatientDetailsPage,
  },
  {
    id: 'general-consent-wellness',
    label: 'reg_kit.consents.common.title',
    component: DoctorGeneralConsentWellnessFormPage,
  },
  {
    id: 'successful-completion',
    label: 'reg_kit.questionnaire.common.title',
    component: NutriBioSuccessfullRegistrationPage,
  },
  {
    id: 'kit-registration-answers-review',
    label: 'Έλεγχος και οριστικοποίηση εγγραφής',
    component: KitRegistrationAnswersReview,
  },
];

const nutrigenixRegistrationPages = [
  {
    id: 'select-report-language',
    label: 'reg_kit.select_report_locale.common.title',
    component: SelectReportLanguageForm,
  },
  {
    id: 'user-details',
    label: 'reg_kit.personal_data.common.title',
    component: UserDetailsWithHealthcareProfessionalDetailsPage,
  },
  {
    id: 'general-consent-wellness',
    label: 'reg_kit.consents.common.title',
    component: GeneralConsentWellnessFormPage,
  },
  {
    id: 'sample-collection',
    label: 'reg_kit.sample.common.title',
    component: SampleCollectionPage,
  },
  {
    id: 'successful-completion',
    label: 'reg_kit.questionnaire.common.title',
    component: NutriBioSuccessfullRegistrationPage,
  },
  {
    id: 'kit-registration-answers-review',
    label: 'Έλεγχος και οριστικοποίηση εγγραφής',
    component: KitRegistrationAnswersReview,
  },
];

const bioAgeRegistrationPages = [
  {
    id: 'select-report-language',
    label: 'reg_kit.select_report_locale.common.title',
    component: SelectReportLanguageForm,
  },
  {
    id: 'user-details',
    label: 'reg_kit.personal_data.common.title',
    component: UserDetailsWithHealthcareProfessionalDetailsPage,
  },
  {
    id: 'general-consent-cvd',
    label: 'reg_kit.consents.common.title',
    component: GeneralConsentBioAgeFormPage,
  },
  {
    id: 'questionnaire',
    label: 'reg_kit.questionnaire.common.title',
    component: NutriBioSuccessfullRegistrationPage,
  },
  {
    id: 'kit-registration-answers-review',
    label: 'Έλεγχος και οριστικοποίηση εγγραφής',
    component: KitRegistrationAnswersReview,
  },
];

const doctorBioAgeRegistrationPages = [
  {
    id: 'select-report-language',
    label: 'reg_kit.select_report_locale.common.title',
    component: SelectReportLanguageForm,
  },
  {
    id: 'user-details',
    label: 'reg_kit.personal_data.common.title',
    component: PatientDetailsPageBioAge,
  },
  {
    id: 'general-consent-cvd',
    label: 'reg_kit.consents.common.title',
    component: DoctorGeneralConsentWellnessFormPage,
  },
  {
    id: 'questionnaire',
    label: 'reg_kit.questionnaire.common.title',
    component: NutriBioSuccessfullRegistrationPage,
  },
  {
    id: 'kit-registration-answers-review',
    label: 'Έλεγχος και οριστικοποίηση εγγραφής',
    component: KitRegistrationAnswersReview,
  },
];

const KitRegistration = ({ kitId, pages }) => {
  const { activePageId, navigateToPage } = useNavigation();
  const history = useHistory();

  const resolveNextPage = (currentPageId) => {
    const idx = pages.findIndex((p) => {
      return p.id === currentPageId;
    });

    if (idx === pages.length - 1) {
      setTimeout(() => {
        history.push({
          pathname: '/',
        });
      }, 3000);
      return;
    }

    navigateToPage({ pageId: pages[idx + 1].id });
  };

  const resolvePrevPage = (currentPageId) => {
    const idx = pages.findIndex((p) => {
      return p.id === currentPageId;
    });

    if (0 === idx) {
      return;
    }

    navigateToPage({ pageId: pages[idx - 1].id });
  };

  const handleSubmit = (currentPageId) => {
    resolveNextPage(currentPageId);
  };

  return (
    <>
      {
        pages.map((p) => {
          const { component: Page } = p;
          return (
            <div
              key={ p.id }
              style={ {
                ...activePageId !== p.id && { display: 'none' },
                width: '100%',
                minHeight: '50vh',
              } }
            >
              <Page
                kitId={ kitId }
                onSubmit={ () => {
                  handleSubmit(p.id);
                } }
                goToPrevPage={ () => {
                  window.scrollTo(
                    { top: 0,
                      behavior: 'smooth' },
                  );
                  resolvePrevPage(p.id);
                } }
              />
            </div>
          );
        })
      }
    </>
  );
};

export const SimpleDashboardWrapper = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={ classes.root }>
      <SectionAlternate className={ classes.section }>
        {children}
      </SectionAlternate>
    </div>
  );
};

export const LoaderSkeleton = () => {
  const heights = [ 130, 50, 30, 80, 50, 20 ];
  return (
    <Grid container spacing={ 3 }>
      {
        heights.map((height, i) => {
          return (
          // eslint-disable-next-line react/no-array-index-key
            <Grid item xs={ 12 } key={ `${ height }-${ i }` }>
              <Skeleton
                animation='wave'
                variant='rect'
                width='100%'
                height={ height }
                style={ { borderRadius: '15px' } }
              />
            </Grid>
          );
        })
      }
    </Grid>
  );
};

const NewKitRegistrationPage = () => {
  const { navigateToPage } = useNavigation();
  const { isLoading: kitsLoading, kitsPending, refetch } = useKits();
  const { isDoctor, isCorporate } = useAuth();

  if (kitsLoading) {
    return <LoaderSkeleton />;
  }

  return (
    <div style={ { width: '100%' } }>
      {
        !isDoctor && !isCorporate && (
          <SerialNumberRegistrationForm
            onSubmit={ (data) => {
              navigateToPage({ pageId: data.id });
            } }
          />
        )
      }
      {
        (kitsPending !== undefined && 0 !== kitsPending.length)
        && (
          <KitsList
            kits={ kitsPending }
            refetch={ refetch }
            onClick={ (kit) => {
              navigateToPage({ pageId: kit.id });
            } }
          />
        )
      }
    </div>
  );
};

export const KitCompleteRegistrationPage = () => {
  const { activePageId: kitId } = useNavigation();

  const { isDoctor, isCorporate } = useAuth();

  const { fetchKits } = useFetching();
  const { isLoading: kitsLoading } = useKits(fetchKits);

  const { isKitPGX, isKitWellness, isKitNutri, isLoading: kitLoading, isKitBioAge, data, isRepeat } = useKit(kitId);

  if (kitsLoading || kitLoading) {
    return (
      <Grid container item xs={ 12 } alignItems='center' justifyContent='center'>
        <Grid item xs={ 12 } md={ 8 }>
          <CardBase withShadow align='left'>
            <LoaderSkeleton />
          </CardBase>
        </Grid>
      </Grid>
    );
  }

  const upgradedFrom = null != data?.ancestor_id;

  let pages;

  if (upgradedFrom && isKitWellness && isDoctor) {
    pages = doctorUpgradeWellnessRegistrationPages;
  } else if (upgradedFrom && isKitNutri && (isDoctor || isCorporate)) {
    pages = doctorUpgradeNutrigenixRegistrationPages;
  } else if (isKitNutri && (!isDoctor && !isCorporate)) {
    pages = nutrigenixRegistrationPages;
  } else if (isKitNutri && isDoctor) {
    pages = doctorNutrigenixRegistrationPages;
  } else if (isKitNutri && isCorporate) {
    pages = corporateNutrigenixRegistrationPages;
  } else if (isKitBioAge && isDoctor) {
    pages = doctorBioAgeRegistrationPages;
  } else if (isKitBioAge) {
    pages = bioAgeRegistrationPages;
  } else if (isKitPGX && isDoctor) {
    pages = doctorPGxRegistrationPages;
  } else if (isKitPGX && isCorporate) {
    pages = corporatePGxRegistrationPages;
  } else if (isKitPGX && !isDoctor && !isCorporate) {
    pages = pgxRegistrationPages;
  } else if (isKitWellness && isDoctor) {
    pages = doctorWellnessRegistrationPages;
  } else if (isKitWellness && !isDoctor && !isCorporate) {
    pages = wellnessRegistrationPages;
  }

  if (isRepeat) {
    return (
      <RepeatQuestionnairePage kitId={ kitId } />
    );
  }

  return (
    <div style={ { marginTop: isDoctor ? '0' : 0 } }>
      <NavigationProvider pageKey='step' fallbackPageId='select-report-language'>
        <KitRegistrationSteps steps={ [ ...pages ] } isKitPGX={ isKitPGX } />
        <Grid container item xs={ 12 } alignItems='center' justifyContent='center'>
          <Grid item xs={ 12 } md={ 12 } lg={ 9 }>
            <CardBase withShadow align='left'>
              <KitRegistration kitId={ kitId } pages={ pages } />
            </CardBase>
          </Grid>
        </Grid>
      </NavigationProvider>
    </div>
  );
};

const KitRegistrationResolver = () => {
  const { activePageId } = useNavigation();
  const { isDoctor, isCorporate } = useAuth();

  return (
    <SimpleDashboardWrapper>
      {
        !activePageId
          ? (
            <Grid
              container
              item
              xs={ 12 }
              alignItems='center'
              justifyContent='center'
              style={ { marginTop: (isDoctor || isCorporate) ? '-100px' : '' } }
            >
              <Grid item xs={ 12 } md={ 8 }>
                <CardBase withShadow align='left'>
                  <NewKitRegistrationPage />
                </CardBase>
              </Grid>
            </Grid>
          )
          : (
            <KitCompleteRegistrationPage />
          )
      }
    </SimpleDashboardWrapper>
  );
};

const KitRegistrationDashboard = () => {
  const { userIsAuthenticated, userAttributes } = useAuth();

  let fallbackPageId = '';

  if (userIsAuthenticated && null != userAttributes) {
    const { fetchKits } = useFetching();
    const { data: kits, isLoading } = useKits(fetchKits);
    const { setFallBackPage } = useFallBackPage();

    if (isLoading) {
      return <LoaderSkeleton />;
    } if (null == kits) {
      return null;
    }

    if (1 === kits.length && ('sample_pending' === kits[0].registrationStatus)) {
      fallbackPageId = `${ kits[0].id }`;
    } else {
      fallbackPageId = '';
      if (null != setFallBackPage) {
        setFallBackPage('general');
      }
    }
  }

  return (
    <NavigationProvider pageKey='kitId' fallbackPageId={ fallbackPageId }>
      <KitRegistrationResolver />
    </NavigationProvider>
  );
};

export default KitRegistrationDashboard;

/*
<Container style={ { marginTop: '12px' } }>
        <Alert severity='success'>
          <AlertTitle>
            {t('successfulKitRegistration')}
          </AlertTitle>
          {t('successfulKitRegistrationContent')}
        </Alert>
      </Container>

      {
    id: 'entitle-third-party',
    label: 'Αποστολή αποτελεσμάτων',
    component: EntitlePersonalResultsPage,
  },
*/

